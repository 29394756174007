.ingredients_item_parent_container {
    /* background-color: rgb(255, 255, 255); */
    border-radius: 7px;
    position:relative;
    height:100%;
    display:inline-block;
    cursor:pointer;
    transition: all 0.1s ease-in-out;
    user-select: none;
    
}

.ingredients_item_parent_container:hover {
    box-shadow: 0 0 5px rgb(187, 187, 187);
    transform: scale(1.05);
}

.ingredients_item_status_container {
    width:30px;
    height:30px;
    position:absolute;
    /* background-color: green; */
    z-index:100;
    border-radius: 50%;
    right:5px;
    top:5px;
    transition: all 0.1s ease-in-out;
    opacity:0;
}

.ingredients_item_status_icon {
    color:white;
    top:50%;
    left:50%;
    position:relative;
    transform: translate(-50%, -50%);
    font-size:20px;
}

.ingredients_item_not_completed {
    
    padding:10px;
    cursor: pointer;
    user-select: none;
    box-sizing: border-box;
    border-radius: 7px;
}

.ingredients_item_completed {
    padding:10px;
    border-radius: 7px;
    cursor: pointer;
    user-select: none;
    box-sizing: border-box;
}

.ingredients_item_image_container {
    width:122px;
    height:122px;
    position:relative;
    border-radius: 7px;
    overflow: hidden;
}

.ingredients_item_image {
    object-fit: cover;
    min-width:100%;
    min-height:100%;
    width:100%;
}

.ingredients_item_name {
    position:relative;
    line-height: 18px;
    font-size:14px;
    display:block;
    padding: 10px 5px 0px; 
    text-align: center;
    margin:0px;
}

@media screen and (max-width: 768px) {

    .ingredients_item_parent_container {
        background-color: rgb(255, 255, 255);
        border-radius: 7px;
        position:relative;
        height:100%;
        display:inline-block;
    }
    
    .ingredients_item_parent_container:hover {
        box-shadow: 0 0 5px rgb(187, 187, 187);
        transform: none;
    }
    
    .ingredients_item_not_completed {
        padding:10px;
        cursor: pointer;
        user-select: none;
        box-sizing: border-box;
        border-radius: 7px;
    }
    
    .ingredients_item_completed {
        padding:10px;
        border-radius: 7px;
        cursor: pointer;
        user-select: none;
        box-sizing: border-box;
    }
    
    .ingredients_item_image_container {
        width:calc(((100vw - 2em)/2) - 30px);
        height:calc(((100vw - 2em)/2) - 30px);
        position:relative;
        border-radius: 7px;
        overflow: hidden;
    }
    
    .ingredients_item_image {
        object-fit: cover;
        min-width:100%;
        min-height:100%;
        width:100%;
    }
    
    .ingredients_item_name {
        position:relative;
        line-height: 14px;
        font-size:14px;
        display:block;
        padding: 10px 5px 0px; 
        text-align: center;
        margin:0px;
    }

    .ingredients_item_status_container {
        display:none;
    }

    .ingredients_item_status_icon {
        display:none;
    }

}