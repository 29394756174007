.filter_display_container {
    position:absolute;
    z-index:10000;
    transition: opacity 0.5s ease-in-out;
}

.filter_display_container.show {
    opacity: 1;
}

.filter_display_child_container {
    background-color: rgb(250, 250, 250);
    position:relative;
    left:-20px;
    top:10px;
    box-shadow: 0 0 8px rgb(146, 146, 146);
    width: 250px;
    max-height:500px;
    border-radius: 7px;
    overflow-y:auto;
}

.filter_title {
    /* background-color: pink; */
    padding:10px 20px 0px 20px ;
    margin:0px;
    color:black;
    font-size:inherit;
    font-weight:500;
}

.filter_list {
    /* background-color: blue; */
    padding:0px 10px 0px;
    columns: 2;
    column-gap:0px;
}


.filter_display_close {
    position:absolute;
    padding:5px;
    top:12px;
    right:10px;
    color:grey;
    /* line-height:0px; */
    /* height:10px; */
    /* width:10px; */
    border-radius: 5px;
    font-size:20px;
    font-weight:500;
}

.filter_display_close:hover {
    /* color:rgb(255, 255, 255); */
    background-color: rgb(241, 241, 241);
}

.filter_display_done {
    font-family: inherit;
    border-style: none;
    width:100%;
    color:rgb(82, 136, 218);
    font-weight:500;
    background-color: transparent;
    margin:0px;
    font-size:inherit;
    cursor:pointer;
}

.filter_display_done:hover {
    color: rgb(0, 95, 236);
}