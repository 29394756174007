.header_container {
    /* background-color: red; */
    position:absolute;
    bottom:10px;
    left:15px;
    margin:0px;
    padding:0px;
    max-width:calc(100% - 30px);
    z-index:3;
    user-select:none;
}

.carousel_wide_content_recipe_name {
    position:relative;
    font-size: 18px;
    margin:0px;
    color:rgb(255, 255, 255);
    padding:3px 10px;
    border-radius: 5px;
    white-space:nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
    background-color: var(--primary-color);
    font-size: 14px;
    font-weight:400;
}

.recipe_header_create_type {
    display:inline-block;
    position:relative;
    font-size: 18px;
    margin:0px;
    color:rgb(255, 255, 255);
    padding:3px 10px;
    border-radius: 5px; 
    background-color:  var(--cancel-color);
    font-size: 14px;
    font-weight:400;
    margin-bottom:3px;
}