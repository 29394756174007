.alert_message_container {
    position:fixed;
    /* top: 110%; */
    left: 50%;
    height: 50px;
    z-index: 1000;
    color:white;
    padding:0px 10px 0px 30px;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 2px solid white;
    transform: translateX(-50%);
    transition: all 0.4s ease;
    user-select: none;
    font-weight:500;
    white-space: nowrap;
}

.alert_message_close_button {
    position: relative;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.4s ease;
    background-color: transparent;
    /* margin:0px 5px; */
}

.alert_message_close {
    position:absolute;
    padding:5px;
    color:grey;
    border-radius: 5px;
    font-size:20px;
    font-weight:500;
    /* background-color: rgb(0, 172, 0); */
    background-color: white;
    color:var(--primary-color);
}

.alert_message_close:hover {
    /* color:rgb(255, 255, 255); */
    background-color: rgb(240, 240, 240);
}

.alert_message_link:hover {
    text-decoration: underline;
    cursor:pointer;
}

.alert_message_text {
    margin:10px;
}

@media screen and (max-width: 768px) {
    .alert_message_container {
        width: calc(100% - 4em);
        padding: 0px 0px 0px 1.5em;
        font-size: 14px;
        border-radius:10px;
        border-style:none;
        border: 2px solid white;
        height: auto;
        opacity: 1;
        white-space:normal;
        box-sizing: border-box;
        transition: all 0.4s ease;
    }

    .alert_message_close_button {
        position: relative;
        top: 0;
        right: 0;
        float:right;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.4s ease;
        background-color: transparent;
        /* margin:0px 5px; */
    }

    .alert_message_close {
        position:absolute;
        padding:5px;
        color:grey;
        border-radius: 5px;
        font-size:15px;
        font-weight:500;
        /* background-color: rgb(0, 172, 0); */
        background-color: white;
        color:var(--primary-color);
        width:15px;
        height:15px;
    }
}