.recipe_search_container {
    position:relative;
    /* width:100vw; */
    /* background-color: pink; */
    padding-bottom:50px;
    /* background: linear-gradient(to bottom, #ddffce, #ffd8d8, white ); */

}

.recipe_search_background {
    position:absolute;
    width:100%;
    height:100%;
    z-index:-1;
    /* background: linear-gradient(to bottom, #ddffce, #ffd8d8, white ); */
    /* background: linear-gradient(to bottom, #000, #66a5ad, #66a5ad, red); */
    background-image: url('https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/shopping_list.jpg');
    background-repeat:repeat;
    opacity: 0.2;
}


h2 {
    margin:0px;
}

.recipe_search_child_container {
    width:60vw;
    min-width:960px;
    margin:auto;
    top:50px;
    position:relative;
}

.recipe_search_title_parent_container {
    background-color: rgb(153, 82, 94);
    margin:auto;
    position:relative;
    min-width:960px;
    width:80vw;
    padding:30px 0px 20px 0px;
}

.recipe_search_title_container {
    /* background-color: blueviolet; */
    position:relative;
    width:80vw;
    min-width:960px;
    margin:auto;
}

.recipe_search_title {
    /* background-color: blue; */
    margin:0px 0px 40px 0px;
    padding-top:50px;
    font-size:44px;
    font-weight:500;
    color:rgb(56, 56, 56);
    white-space:normal;
    text-align: center;
}

.recipe_search_introduction {
    line-height: 150%;
    font-size: 16px;
    white-space:normal;
}

.filter_parent_container {
    position:relative;
    top:-30px;
    min-width:250px;
    width:20vw;
    height:100px;
    /* background-color: red; */
    display:inline-block;
    
}

.recipe_search_serach_ui {
    /* background-color: blue; */
    position:relative;
    top:0px;
    height:50px;
    margin:25px 0px 25px 0px;
}

.recipe_search_see_more {
    position:relative;
    margin:auto;
    /* background-color: var(--primary-color); */
    background-color: rgb(240, 240, 240);
    /* width:100%; */
    /* width:250px; */
    top:10px;
    height:50px;
    color:rgb(92, 92, 92);
    margin-bottom:30px;
    font-weight:500;
    font-size:16px;
    border-radius: 10px;
    padding:0px 30px;
    animation-name: up-down;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    box-shadow: 0 0 10px rgb(143, 143, 143);
}   

.recipe_search_see_more:hover {
    background-color: var(--primary-color-hover);
    color:white;
}

.recipe_search_no_results_container {
    height:400px;
    /* background-color: pink; */
    padding-bottom:50px;
}

.recipe_serach_no_results_content {
    position:relative;
    top:50%;
    transform: translateY(-50%);
    text-align: center;
}

.recipe_search_no_results_title {
    font-weight:500;
    /* font-size:24px; */
}

.recipe_search_no_results_text {
    line-height:150%;
}

.recipe_search_no_results_suggestion {
    height:30px;
    width:400px;
    border-radius: 5px;
    padding:10px;
    border: 2px solid var(--primary-color);
    font-family:inherit;
    text-align: center;
    font-size:18px;
    font-weight:500;
    margin:20px;
}

.recipe_search_no_results_suggestion:focus {
    outline: var(--primary-color) solid 1px;
}

.recipe_search_no_results_submit {
    width:250px;
    border-radius: 20px;
    background-color: var(--primary-color);
    color:rgb(235, 235, 235);
    height:40px;
    border-style: none;
    margin:20px 0px 15px 0px;
    cursor:pointer;
    transition: all .4s ease;
    font-weight:500;

}

.recipe_search_no_results_submit:hover {
    color:rgb(255, 255, 255);
    box-shadow: 0 0 20px rgb(255, 255, 255);
    transition: all .4s ease;
    background-color: var(--primary-color-hover)
}


@media screen and (max-width: 1000px) {
    .recipe_search_child_container {
        width:100vw;
        min-width:100vw;
    }

    .recipe_search_title_parent_container {
        min-width:100vw;
    }

    .recipe_search_title_container {
        min-width:100vw;
    }

    .recipe_search_title {
        font-size:32px;
    }

    .recipe_search_introduction {
        font-size:14px;
    }

    .filter_parent_container {
        width:100vw;
        min-width:100vw;
    }

    .recipe_search_see_more {
        width:100vw;
        min-width:100vw;
    }

    .recipe_search_no_results_container {
        height:400px;
        /* background-color: pink; */
        padding-bottom:50px;
        box-sizing: border-box;
        padding:20px;
    }

    .recipe_serach_no_results_content {
        position:relative;
        top:50%;
        transform: translateY(-50%);
        text-align: center;
    }

    .recipe_search_no_results_title {
        font-weight:500;
        font-size:22px;
    }

    .recipe_search_no_results_text {
        line-height:150%;
    }

    .recipe_search_no_results_suggestion {
        height:30px;
        width:100%;
        border-radius: 5px;
        padding:0px;
        border: 2px solid var(--primary-color);
        font-family:inherit;
        text-align: center;
        font-size:18px;
        font-weight:500;
        margin:0px;
        height:50px;
    }

    .recipe_search_no_results_suggestion:focus {
        outline: var(--primary-color) solid 1px;
    }

    .recipe_search_no_results_submit {
        width:250px;
        border-radius: 20px;
        background-color: var(--primary-color);
        color:rgb(235, 235, 235);
        height:40px;
        border-style: none;
        margin:20px 0px 15px 0px;
        cursor:pointer;
        transition: all .4s ease;
        font-weight:500;
    }

    .recipe_search_see_more {
        position:relative;
        margin:auto;
        /* background-color: var(--primary-color); */
        background-color: rgb(240, 240, 240);
        /* width:100%; */
        /* width:250px; */
        top:10px;
        height:50px;
        color:rgb(92, 92, 92);
        margin-bottom:30px;
        font-weight:500;
        font-size:16px;
        border-radius: 10px;
        padding:0px 30px;
        animation-name: up-down;
        animation-duration: 2s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        box-shadow: 0 0 10px rgb(143, 143, 143);
        width:auto;
        min-width:0px;
    }   
}