.create_content_ingredients_header_container {
    margin-bottom:20px;
}

.create_content_ingredients_title_container {
    display:inline-block;
}

.create_content_ingredients_title {
    font-size: 28px;
    font-weight: 500;
    margin:0px;
    padding: 0px;
    display:inline-block;
}

.create_content_ingredients_add_item_container {
    width:100%;
    /* height:70px; */
    /* box-shadow: 0 0 10px 0 rgba(0,0,0,.1); */
    border-radius: 7px;
    padding:10px;
    background-color: rgb(240,240,240);
    box-sizing: border-box; 
    vertical-align: middle;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 10px;
}

.create_content_ingredients_item_selection {
    display:inline-block;
}

.create_content_ingredients_add_item_title {
    font-weight:500;
    font-size: 12px;
    padding:0px;
    margin:0px 0px 5px;
}

.create_content_ingredients_add_item_selection {
    /* background-color: white; */
    width:100%;
    height:40px;
    border-radius: 5px;
}

.create_content_ingredients_item_selection_add {
    display:inline-block;

}

.create_content_ingredients_add_item_button {
    color:white;
    font-weight:500;
    display:inline-block;
    background-color: var(--primary-color);
    height:38px;
    padding:0px 40px;
    width:100%;
}
.create_content_ingredients_add_item_button:hover {
    background-color: var(--primary-color-hover);
}

.create_content_ingredients_how_to {
    font-weight:300;
    font-size: 14px;
}

.create_content_ingredients_how_to_button {
    cursor: pointer;
    color:rgb(51, 102, 204);
}

.create_content_ingredients_how_to_button:hover {
    text-decoration: underline;
}

.create_content_ingredients_servings_container {
    float:right;
}

.create_content_ingredients_servings_label {
    font-weight:500;
    line-height:28px;
    display:inline-block; 
    margin:0px 10px 0px 0px ;
}   

.create_content_ingredients_servings_input {
    width:50px;
    height:30px;
    display:inline-block;
    font-weight:500;
    text-align:center;
    font-size:20px;
    border:none;
    background-color: rgb(240,240,240);
    border-radius: 5px;
    font-size:18px;
    border:2px solid transparent;
}


.create_content_ingredients_servings_input:focus {
    outline:none;
}

@media only screen and (max-width: 768px) {
    .create_content_ingredients_title {
        font-size: 28px;
    }

    .create_content_ingredients_add_item_container {
        display: block;
    }

    .create_content_ingredients_item_selection {
        width:100%;
        margin-bottom:10px;
    }

    .create_content_ingredients_item_selection_add {
        width:100%;
    }

    .create_content_ingredients_add_item_button {
        color:white;
        font-weight:500;
        display:inline-block;
        background-color: var(--primary-color);
        height:auto;
        padding:15px 40px;
        width:100%;
    }

    .create_content_ingredients_servings_container {
        float:none;
        margin-top:10px;
    }

    .create_content_ingredients_servings_label {
        margin:0px 10px 0px 0px ;
    }   

    .create_content_ingredients_servings_input {
        width:50px;
        height:30px; 
        font-weight:500;
        text-align:center;
        font-size:20px;
        border:none;
        background-color: rgb(240,240,240);
        border-radius: 5px;
        font-size:18px;
        border:2px solid transparent;
        float:right;
    }


}
