.home_container {
    position:relative;
    overflow-x:hidden;
    top:0px;
}

.home_hero_image_container {
    position:relative;
    height:650px;
    overflow:hidden;

}

.home_hero_image_picture_container {
    position:relative;
    height:100vh;
    overflow:hidden;
    /* display: flex; */
    /* justify-content: center; */
    /* text-align: center; */
    /* align-items: center; */
}

.home_hero_image {
    /* position: absolute; */
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position:relative;
    object-fit:cover;
    min-width:100%;
    max-height:100%;
}

.home_hero_image_title_container {
    /* background-color: pink; */
    position:absolute;
    /* width:350px; */
    top:47%;
    left:50%;
    /* min-width:960px; */
    transform:translate(-50%, -50%);
    text-align: center;
    min-width:960px;
}

.home_hero_image_title {
    position:relative;
    border-radius: 10px;
    font-weight:500;
    font-size:28px;
    color:rgb(4, 4, 4);
}

.home_title_secondary {
    /* background-color: blue; */
    position:relative;
    width:650px;
    font-size:24px;
    margin:20px 0px 20px;
    color:white;
    line-height: 130%;
    padding:0px;
}

.home_search_container {
    /* background-color: red; */
    position:relative;
    width:500px;
    height:45px;
}

.break_line {
    border-top:1px solid rgb(226, 223, 223);
    margin:auto;
    min-width:960px;
    max-width:1400px;
    width:85vw;
}

.container {
  position: relative;
  width: 200px;
  height: 200px;
  border: 2px solid black;
  border-radius: 50%;
  overflow: hidden;
}

.home_hero_mask_temp {
    position:absolute;
    top:-50%;
    height:200vh;
    width:100%;
    left:-54%;
    /* transform: translate(-50%, -50%); */
    background-color: rgb(241, 241, 241, 0.5);
    transform: rotate(7deg);
}

@media screen and (max-width: 768px) {

    .home_hero_image_container {
        position:relative;
        height:90vh;
        overflow:hidden;
    
    }

    .home_hero_image_title_container {
        /* background-color: pink; */
        position:absolute;
        /* width:350px; */
        top:70%;
        left:50%;
        /* min-width:960px; */
        transform:translate(-50%);
        text-align: center;
        min-width:960px; 

    }


}
