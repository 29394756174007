.recipe_tile_container {
    background-color: rgb(245, 245, 245);
    position:relative;
    height:100%;
    border-radius: 10px;
    overflow: hidden;
    z-index:10;
    transition: all .4s ease;
    box-shadow: 0 0 20px rgb(180, 180, 180);
    padding:0px;
    user-select:none;
    cursor:pointer;
    max-width:690px;
    display:inline-block;
    /* margin-right:20px; */
}

.recipe_tile_container:hover {
    transform: scale(1.03);
    transition: all .4s ease;
}

.recipe_tile_img {
    position:absolute;
    top:50%;
    width:100%;
    transform: translate(0, -50%);
    color:transparent;
    border:transparent;
}

.recipe_tile_favourite_button {
    position:absolute;
    right:10px;
    top:8px;
}

.place_holder_container {
    background: linear-gradient(to bottom, #ffffff, #f2f2f2);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    position: relative;
}

@media screen and (max-width: 768px) {

    .recipe_tile_container {
        background-color: rgb(245, 245, 245);
        position:relative;
        height:100%;
        border-radius: 10px;
        overflow: hidden;
        z-index:2;
        transition: all .4s ease;
        box-shadow: none;
        /* padding:20px; */
        user-select:none;
        cursor:pointer;
        max-width:690px;
        display:inline-block;
        margin-right:10px;
        
    }

    .recipe_tile_container:hover {
        transform: none;
        /* transition: all .4s ease; */
    }

    .recipe_tile_img {
        position:absolute;
        top:50%;
        height:100%;
        object-fit: cover;
        transform: translate(0, -50%);

    }
    .recipe_tile_favourite_button {
        position:absolute;
        height:24px;
        width:25px;
        background-color:transparent;
        right:8px;
        top:8px;
    }

}