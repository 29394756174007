.recipe_introduction_container {
    padding: 0px;
    background-color: white;
    margin:0px 0px 30px;
}

.recipe_name {
    position:relative;
    font-size:50px;
    font-weight:500;
    font-family:inherit;
    margin:0px;

    /* min-height:60px; */
}

.recipe_introduction_statistic_container {
    margin-top:12px;
}

.recipe_title_placeholder {
    position:relative;
    width: 300px;
    height:60px;
    margin-bottom:20px;
}

.recipe_introduction_text_show {
    margin:0px;
    font-size:18px;
    line-height: 150%;
}

.recipe_introduction_text_show_admin {
    margin:0px;
    font-size:18px;
    line-height: 150%;
    width:100%;
    height:300px;
    font-family:inherit;
    box-sizing: border-box;
}

.recipe_introduction_placeholder {
    position:relative;
    /* width: 100%; */
    height:200px;
    margin-top:10px;
    margin-bottom:0px;
}

.update_recipe_introduction_button {
    background-color: var(--primary-color);
    padding:10px 20px;
    color:white
}

.recipe_ingredients_admin_update_container {
    position:relative;
}

.recipe_author {
    font-weight:500;
    color:rgb(103, 103, 103);
    font-size: 16px;
    margin-bottom:20px;
}

.recipe_stats {
    font-weight:400;
    font-size:15px;
}

.recipe_stats_variations:hover {
    text-decoration: underline;
    cursor: pointer;

}

.recipe_stats_new_version_message {
    display:inline-block;
    padding:5px 10px;
    background-color: var(--primary-color);
    color:white;
    font-weight:500;
    border-radius:5px;
    margin-bottom:20px;
    cursor:pointer;
    font-size:14px;
}

.recipe_author_link {
    cursor: pointer;
}

.recipe_author_link:hover {
    text-decoration: underline;
}

@media screen and (max-width: 768px) {

    .recipe_introduction_container {
        padding: 0px 1em;
        background-color: white;
        margin-top:0px;
        margin-bottom:0px;
    }

    .recipe_name {
        font-size:36px;
        font-weight:500;
        font-family:inherit;
        margin:0px;
        padding:30px 0px 20px 0px;
        line-height:normal;
        margin-bottom:0px;
        min-height:auto;
    }

    .recipe_author {
        white-space: nowrap;
        overflow: scroll;
    }

    .recipe_introduction_statistic_container {
        margin-top:0px;
    }
    
    .recipe_title_placeholder {
        position:relative;
        width: 300px;
        height:40px;
        padding-top:20px;
        margin:0px;
        margin-bottom:30px;
        /* background-color: rgb(191, 13, 13); */
    }

    .recipe_introduction_text_hide {
        margin:0px;
        font-size:18px;
        line-height:150%;
        display: -webkit-box; /* Set display property to use box layout */
        -webkit-box-orient: vertical; /* Set box orientation to vertical */
        -webkit-line-clamp: 3; /* Limit the number of lines to 3 */
        overflow: hidden;
        transition:all 0.5s ease;
    }

    .recipe_introduction_text_show {
        margin:0px;
        font-size:18px;
        line-height:150%;
    }

    .recipe_introduction_placeholder {
        position:relative;
        /* width: 100%; */
        height:113px;
        margin-top:10px;
        margin-bottom:0px;
    }

    .recipe_introduction_show_more {
        display:inline-block;
        font-family:inherit;
        font-size:18px;
        /* line-height:150%; */
        height:36px;
        line-height:36px;
        position:relative;
        color:rgb(6,69,173);
        cursor: pointer;
        transition:all 0.5s ease;
    }

    .recipe_stats_new_version_message {
        display:inline-block;
        padding:5px 10px;
        background-color: var(--primary-color);
        color:white;
        font-weight:500;
        border-radius:5px;
        margin-bottom:0px;
        cursor:pointer;
        font-size:14px;
        margin:20px 0px 0px 0px
    }
 
}