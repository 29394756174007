.introduction_mobile_container {
    position:relative;
    padding-top:50px;
}


.introduction_mobile_header_background {
    position:absolute;
    top:-50px;
    left:0px;
    height:calc(100% + 50px);
    width:100%;
}

.introduction_mobile_header_content_container {
    width:100%;
    margin:auto;
    position:relative;
    height:100%;
    overflow:hidden;
    text-align: center;
    padding:2em 1em 1em;
    box-sizing: border-box;
}

.introduction_mobile_header_title {
    margin:0px;
    font-size:36px;
}

.introduction_mobile_header_info {
    font-size:18px; 
}


.introduction_mobile_header_image {
    border-radius:10px;
    width:100%;
    margin-top:0px;
    top:0px;
}

.introduction_mobile_section_container {
    height:480px;
    margin-bottom:10px;
    background-color: rgb(245, 245, 245); 
    position:relative; 
    text-align: center;
}

.introduction_mobile_section_background {
    position:absolute;
    top:0px;
    left:0px;
    height:100%;
    width:100%;
    background-image: url('https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/shopping_list.jpg');
    background-repeat:repeat;
    opacity: 0.2;
}

.introduction_mobile_section_first {
    background-color: #FBDA61;
    background-image: linear-gradient(45deg, #FBDA61 0%, #FF5ACD 100%);
}

.introduction_mobile_section_content_container {
    width:100%;
    padding:1em;
    box-sizing: border-box; 
    position:relative;
}

.introduction_mobile_section_content {
    position:relative;
    transform:none;
    top:0px;
    margin-top:1em;
}

.introduction_mobile_secion_header {
    margin:0px;
    font-size:36px;
}

.introduction_mobile_section_text {
    font-size:18px;
    line-height:150%;
}

.introduction_mobile_section_button {
    margin-top:20px;
}

.introduction_mobile_section_text_container {
    width:100%;
    height:auto;
    padding-right:0px;
} 
.introduction_mobile_section_image_container {
    width:100%;
    padding-left:0px;
    overflow:hidden;
    margin-top:1em;
    border-radius: 10px;
    
} 

.introduction_mobile_section_curate_image {
    width:101%;
}
