.upload_recipe_parent_container {
    display:inline-block;
    z-index:100; 
}

.upload_recipe_parent_container_hide {
    display:none;
}

.upload_recipe_title {
    padding:10px 20px;
    color:var(--primary-color);
    background-color: white;
    font-weight:500;
    border:2px solid var(--primary-color);
}

.upload_recipe_title:hover {
    background-color: var(--primary-color);
    color:white;
}

.upload_recipe_content { 
    margin:auto;
    background-color: white; 
    position:relative;  
}

.upload_recipe_content_container {
    width:100vw;
    height:100vh;
    position:fixed;
    top:0px;
    left:0px;
    background-color: rgba(0,0,0,0.5);
    z-index:1000;
}   

.upload_recipe_content_ingredients_not_found { 
    margin:auto;
    background-color: white; 
    position:relative;  
}

.upload_recipe_content_header_container {
    position:relative;
    width:100%;
}

.upload_recipe_content_header {
    font-size:30px;
    margin:0px;
    margin-bottom:20px;
    display:inline-block;
}

.upload_recipe_content_body_container_generate {
    height:200px;
    margin-bottom:10px;
    
} 

.upload_recipe_content_body {
    height:100%;
    width:100%;
    font-family: 'Roboto', sans-serif;
    font-size:24px;
    padding:20px;
    box-sizing: border-box;
    border: transparent;
    border-radius: 5px;
    font-weight:500;
    margin-bottom:10px;
    resize: none;
    background-color: rgb(245, 245, 245);
    color:var(--primary-color);
}

.upload_recipe_content_body::before {
    content: attr(data-text); /* Use the content attribute to get the text from the HTML */
    position: absolute;
    top: 0;
    left: 0;
    color: red; /* Color for the first 10 characters */
  }

.upload_recipe_content_body:focus {
    outline:none;
}


.upload_recipe_content_header_buttons_container {
    position:relative; 
    display:grid;
    grid-template-columns: 1fr 1fr;
    grid-gap:5px;
}

.upload_recipe_content_header_button {
    padding:10px 20px;
    color:white;
    background-color: var(--primary-color);
    font-weight:500;
    margin-left:10px;
}

.upload_recipe_content_info {
    margin-top:0px;
}

.upload_recipe_content_footer_container {
    position:relative;
}  

.upload_recipe_content_ingredient_not_found_list {
    padding:0px 15px;
}

.upload_recipe_content_ingredient_item {
    padding: 5px;
}

.upload_recipe_content_generate_error_message {
    margin-bottom:0px;
    color:var(--cancel-color);
    font-size:16px;
    font-weight:500;
}

.upload_recipe_content_recipe_option_container {
    padding:10px;
    background-color: rgb(245, 245, 245);
    border-radius: 5px;
    margin:5px 0px;
    border:2px solid rgb(245, 245, 245);
}

.upload_recipe_content_recipe_option_container_selected {
    padding:10px;
    /* background-color: var(--primary-color); */
    border:2px solid var(--primary-color);
    /* color:white; */
    border-radius: 5px;
    margin:5px 0px;
}

.upload_recipe_content_recipe_option_name {
    font-weight:500;
    display:block; 
}

.upload_recipe_content_recipe_option_container:hover {
    border:2px solid var(--primary-color);
    cursor:pointer;
}

@media only screen and (max-width: 600px) {

    .upload_recipe_content {
        width:100%;
        /* margin:1em; */
        box-sizing: border-box; 
    }

    .upload_recipe_content_header {
        padding:0px 0px 10px;
        margin:0px 0px;
        font-size:20px;
    }

    .upload_recipe_content_ingredients_not_found {
        width:100%;
        /* padding:20px; */
    }

    .upload_recipe_content_body_container {
        height:200px;
    }

    .upload_recipe_content_header_buttons_container {
        float:none;
        display:grid;
        grid-template-columns: 1fr 1fr;
        grid-gap:5px;
        margin-top:5px;
    }
    .upload_recipe_content_footer_container {
        position:relative; 
    }
    
}