.active_filter_item {
    background-color: green;
    padding:8px 15px;
    display:inline-block;
    border-radius: 7px;
    cursor:pointer;
    color:white;
    margin-right:5px;
    margin-bottom:10px;
    font-weight:400;
}

.active_filter_item:hover {
    background-color: rgb(0, 155, 0);
}

.close {
    position:relative;
    line-height:0px;
    top:3px;
    left:2px;
    width:13px;
    font-size: inherit;
}

@media screen and (max-width: 768px) {
    .active_filter_item {
        background-color: green;
        padding:8px 15px;
        display:inline-block;
        border-radius: 5px;
        cursor:pointer;
        color:white;
        margin-right:5px;
        margin-bottom:0px;
        font-size:14px;
        font-weight:400;
    }
}