.ingredient_tile_container {
    background-color: rgb(255, 255, 255);
    border-radius: 7px;
    position:relative;
    padding:10px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    display:inline-block;
    height:100%;
    transition: all 1s ease-in-out;
}

.ingredient_tile_image_container {
    background-color: rgb(240, 240, 240);
    width:100%;
    height:100%;
    border-radius: 7px;
    overflow: hidden;
}   

.ingredient_tile_image {
    width:100%;
    height:100%;
}

.ingredient_tile_text {
    /* background-color: green; */
    /* display:inline-block; */
    text-align: center;
    vertical-align: center;
    padding:10px 0px 0px;
    /* height:50px; */
    box-sizing: border-box;
    margin:auto;
    font-size:14px;
}

.ingredient_tile_initials {
    position:relative;
    text-align: center; 
    top:50%;
    transform: translateY(-50%);
    font-weight:500;
    font-size:55px;
    color:grey;

}