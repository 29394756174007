.results_container {
    /* background-color: green; */
    width:60vw;
    min-width:960px;
    margin:auto;
    display:grid;
    column-gap: 20px;
    row-gap: 20px;
    padding-bottom:20px;
}

.grid_item {
    font-size: 30px;
    width:100%;
    height:230px;
}

@media screen and (max-width: 768px) {
    .results_container {
        width:100%;
        min-width:0px;
        margin:auto;
        display:grid;
        column-gap: 20px;
        row-gap: 20px;
        padding:1em;
        box-sizing: border-box;
    }
}