.edit_handle_panel_container {
    position:fixed;
    top:0px;
    left:0px;
    width:100vw;
    height:100vh;
    background-color:rgba(0,0,0,0.5);
    z-index: 10;
}

.edit_handle_panel_content {
    position:fixed;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    width:500px;
    /* height:500px; */
    background-color:white;
    border-radius:20px;
    box-shadow:0px 0px 10px rgba(0,0,0,0.5);
    padding:40px;
    box-sizing:border-box;
}

.edit_handle_panel_header_title {
    font-size:32px;
    margin-bottom:20px;
    margin-top:0px;
}

.edit_handle_panel_body_item_content {
    position:relative;
}   

.edit_handle_panel_item_input {
    padding:10px 20px;
    box-sizing:border-box;
    width:100%;
    border-radius:5px;
    border:2px solid var(--primary-color);
    outline:none;
    font-size:16px;
    margin-bottom:20px;
}

.edit_handle_panel_item_check_status {
    position:absolute;
    display:inline-block;
    line-height:40px;
    right:10px;
    margin:0px;
    font-size:16px;
    font-weight:500; 
}

.edit_handle_panel_handle_status_text {
    margin:0px;
}