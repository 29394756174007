.terms_of_use_container {
    background-color: rgb(241, 241, 241);
    padding:100px 0px 50px 0px;
}

.terms_of_use_content {
    width:650px;
    margin:auto;
    background-color: white;
    border-radius: 10px;
    padding:50px;
    line-height:150%;
}

@media screen and (max-width: 768px) {
    .terms_of_use_container {
        padding:50px 0px 50px 0px;
    }
    
    .terms_of_use_content {
        width:90vw;
        margin:auto;
        background-color: white;
        border-radius: 10px;
        padding:20px;
        line-height:150%;
        box-sizing: border-box;
        margin-top:20px;
    }
    
     
}