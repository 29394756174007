.desktop_search_button_container {
    /* background-color: red; */
    height:40px;
    width:40px;
    position:relative;
    left:-15px;
}

.desktop_search_container { 
    top:2px;
    position:relative; 
}

.desktop_search_input_container {
    position:absolute;
    background-color: white;
    height:35px;
    top:0px;
    left:0px;
    display:inline-block;
    border-radius: 5px;
    transition: all 0.4s ease;
    overflow:hidden;
}

.desktop_search_icon {
    position:relative;
    line-height: 35px;
    margin: 0px 5px 0px 10px;
    left:80px;
}

.desktop_search_form {
    position:relative;
    display:inline-block;
    margin:0px;
    padding:0px;
    width: 100%;
    height: 100%;
    
}

.desktop_search_input {
    position:relative;
    /* background-color: pink; */
    border:none;
    top:-7px;
    display:inline-block;
    left:85px;
}

.desktop_search_input:focus {
    outline:none;
}

.desktop_search_close_icon {
    position:relative;
    line-height: 35px;
    margin: 0px 10px 0px 5px;
    cursor:pointer;
    /* right:50px; */
}