.create_instructions_step_parent_container {
    position:relative;
    /* margin-bottom:20px; */
    cursor:pointer;
}

.create_instructions_step_container {
    position:relative;
    width:100%;
}

.create_instructions_step_container:hover #create_instruction_remove_id {
    opacity:1;
}

/* #create_instructions_step_text_area_id:focus #create_instructions_outline_id {
    opacity:1;
}  */

.create_instructions_step_outline {
    background-color: transparent;
    width:calc(100% + 40px);
    height:calc(100% + 40px);
    position:absolute;
    left:-20px;
    top:-20px;
    border-radius:5px;
    border: 2px solid var(--primary-color);
    opacity:0;
    /* background-color: rgb(245,245,245) */
}

.create_instructions_step_number_container {
    display:inline-block;
    width:80px;
    vertical-align: top;
    position:absolute;
    /* background-color: blue; */
    left:0px;
    top:0px;
    margin: 20px 0px;
}

.create_instructions_step_number {
    vertical-align: top;
    margin:0px;
    font-size:16px;
    font-weight:500;
}

.create_instructions_step_text_container {
    /* min-height:60px; */
    display:inline-block;
    width:calc(100%);
    position:relative;
}

.create_instructions_step_textarea {
    width:calc(100% + 40px);
    resize:none;
    border:1px solid transparent;
    border-radius:5px;
    font-size:16px;
    font-family: 'Roboto', sans-serif;
    outline:none;
    margin:0px;
    font-weight:300;
    background-color: transparent;
    /* background-color: red; */
    padding:20px 150px 20px 100px;
    height:1em;
    cursor:pointer;
    box-sizing: border-box;
    left:-20px;
    position:relative;
    transition: all 0.4s ease-in-out;
    overflow:hidden;
}

.create_instructions_step_textarea:focus {
    cursor:text;
}

.create_instructions_step_remove {
    background-color: rgb(200, 61, 61);
    padding: 10px 20px;
    color:white;
    font-weight:500;
    position:absolute;
    right:0px;
    top:0px;
    opacity:0;
    transition: all 0.2s ease-in-out;
}

.create_instructions_step_add_here_container {
    position:relative;
    height:20px;
    width:100%;
    background-color: transparent;
    opacity:0.1;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.create_instructions_step_add_here_container:hover {
    opacity:1;
}

.create_instructions_step_add_here {
    position:relative;
    height:5px;
    width:100%;
    background-color: var(--primary-color);
    border-radius: 10px;
    top:50%;
    transform:translateY(-50%);
}

.create_instructions_step_add_here_button {
    position:absolute;
    top:50%;
    right:0px;
    transform:translateY(-50%);
    background-color: var(--primary-color);
    padding:5px 10px;
    color:rgb(255, 255, 255);
    font-size:12px;
    font-weight:500;
}

.create_instructions_step_action_buttons {
    display:none;
    position:absolute;
    right:0px;
    top:20px;
    vertical-align: top;
    text-align: right;
    /* opacity:0; */
}

@media only screen and (max-width: 768px) {
    .create_instructions_step_action_buttons {
        display:block;
    }

    .create_instructions_step_textarea { 
        padding:50px 50px 20px 18px;
        -webkit-tap-highlight-color: transparent;
        box-sizing: border-box;
        overflow:hidden;
        width:100%;
        font-size:18px;
    }

    .create_instructions_step_number_container {
        display:inline-block;
        width:80px;
        vertical-align: top; 
        position:absolute;
        /* background-color: blue; */
        left:0px;
        top:0px; 
        
    }

    .create_instructions_step_number { 
        font-size:18px; 
    }
}