.results_columns_container {
    height:100%;
}

.results_columns_button {
    background-color: rgb(255, 255, 255);
    display: inline-block;
    height:100%;
    width:100%;
    padding:0px 20px;
    border-radius: 7px;
    border-style:none;
    cursor:pointer;
    font-size:inherit;
    color:grey;
}

.results_columns_button:hover {
    background-color: rgb(214, 214, 214);
}

.results_columns_expand_more {
    position:relative;
    line-height:0px;
    top:8px;
    left:-1px;
    width:13px;
    font-variation-settings: 'FILL' 0, 'wght' 250, 'GRAD' 0, 'opsz' 48;
}

.results_columns_options_container {
    background-color: rgb(255, 255, 255);
    border-radius: 7px;
    box-shadow: 0 0 8px rgb(150, 150, 150);
    cursor: pointer;
    margin:0px;
    padding:0px;
}

.results_columns_options_header {
    padding:0px 20px;
    margin:0px;
    font-weight:500;
}

.results_columns_options_list {
    list-style-type: none;
    padding:5px 0px 0px;
    margin:10px 0px 0px;
}

.results_columns_options_list_item {
    padding:0px 20px;
    font-weight:400;
}

.results_columns_options_list_item:hover {
    background-color: rgb(235, 235, 235);
}

.results_columns_done {
    font-family: inherit;
    border-style: none;
    width:100%;
    color:rgb(82, 136, 218);
    font-weight:500;
    background-color: blue;
    margin:0px;
    font-size:inherit;
    cursor:pointer;
    padding:0px;
    line-height: 0px;
}

.results_columns_done:hover {
    color: rgb(0, 95, 236);
}