.reset_password_container {
    position:relative;
    width:100%;
    height:100vh;
    min-height:800px;
}

.reset_password_background {
    position:absolute;
    width:100%;
    height:100%
}

.reset_password_content {
    position:absolute;
    background-color: rgba(240, 240, 240, 0.8);
    backdrop-filter: blur(10px);
    width:400px;
    /* height:30px; */
    margin:auto;
    border-radius: 20px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    padding:50px;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
}

.reset_password_input {
   padding:10px;
    margin:3px;
    width:250px;
    height:20px;
    border-radius: 5px;
    border: 1px solid rgb(199, 199, 199);
}

.reset_password_repsonse {
    font-size:12px;
    color:red;
    white-space: normal;
}