.page_links_container {
    /* background-color: blue; */
    position:relative;
    top:50%;
    transform: translate(0, -50%);
}

.page_links_list {
    /* background-color: pink; */
    
    list-style-type: none;
    padding:0px;
    display:flex;
    justify-content: space-between;
    margin:0px;
}

.page_links_item {
    position:relative;
    text-decoration: none;
    padding:0px 20px;
    height:35px;
    margin:0px 10px;
    white-space: nowrap;
    user-select: none;
    /* background-color: pink; */
    display:flex;
    align-items: center;
    cursor: pointer;
    color:rgb(110, 110, 110);
}

.page_links_item_create {
    position:relative;
    text-decoration: none;
    padding:0px 20px;
    height:35px;
    margin:0px 10px;
    white-space: nowrap;
    user-select: none;
    /* background-color: pink; */
    display:flex;
    align-items: center;
    cursor: pointer;
    color:rgb(255, 255, 255);
}

.page_links_item:hover {
    color:var(--primary-color);
}


.nav_mobile_links_container {
    display:none;
}

.page_links_search_container {
    top:0px;
    left:0px;
    height:50px;
    width:50px;
    position:relative;
    /* background-color: pink; */
}

.page_links_search_icon {
    /* background-color: red; */
    display:inline-block;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    font-size:18px;
    font-variation-settings:
    'FILL' 0,
    'wght' 600,
    'GRAD' 0,
    'opsz' 40;
    cursor:pointer;
    
}

.page_links_search_icon_create {
    /* background-color: red; */
    display:inline-block;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    font-size:18px;
    font-variation-settings:
    'FILL' 0,
    'wght' 600,
    'GRAD' 0,
    'opsz' 40;
    cursor:pointer;
    color:white;
}

@media screen and (max-width: 768px) {

    .page_links_container {
        display:none;
    }

    .nav_mobile_links_container {
        display:inline-block;
    }

    .mobile_page_links_content_container {
        position: absolute;
        left: 0px;
        top: calc(-100vh - 50px);
        /* height: calc(100vh - 50px); */
        /* height: 400px; */
        width: 100vw;
        padding: 50px 30px 25px;
        background-color: rgb(240, 240, 240);
        transition: all 0.5s ease;
        text-align: left;
        z-index: 10;
        box-sizing: border-box;
        box-shadow: 0px 10px 30px 0px rgba(80, 80, 80, 0.75);
        display:visible;
    }
    
    .mobile_page_links_mask {
        display:block;
        position:fixed;
        width:100vw;
        height:100vh;
        background-color:transparent;
        top:0px;
        left:0px;
        transition:all 0.5s ease;
    }
    
    .mobile_page_links_content_container_show {
        top: 0px;
    }
    
    .mobile_page_link {
        position: relative;
        display: block;
        font-size: 2.5em;
        text-decoration: none;
        color: rgb(0, 0, 0);
        /* padding-left: 30px ; */
        padding: 5px 10px;
        border-radius: 10px;
        width:auto;
        margin:0px;
        -webkit-tap-highlight-color: transparent;
    }
    
    .mobile_page_link:hover {
        color: var(--primary-color);
    }
    
    .mobile_sign_up_link_container {
        margin-top:25px;
        color:white;
    }
    
    .mobile_sign_up_link {
        /* background-color: var(--primary-color); */
        /* padding:10px 30px; */
        width:250px;
        border-radius:10px;
        color:var(--primary-color);
        font-weight:1000px;
    }
    
    .nav_show_less_icon {
        position: relative;
        background-color: transparent;
        font-size: 40px;
        color: rgb(0, 0, 0);
        top: 20px;
    }
    
    .mobilee_page_links_hamburger_container {
        background-color: transparent;
        position: absolute;
        right:-100vw;
        /* padding-right:1.5em; */
        height:50px;
        width:55px;
        text-align: center;
        cursor:pointer;
        z-index:1001;
        box-shadow: none;
        -webkit-tap-highlight-color: transparent;
    }
    
    
    .nav_hamburger_button {
        display:inline-block;
        background-color: transparent;
        height:20px;
        width:20px;
        z-index:100;
        padding:0px;
        /* position:absolute; */
    }
    
    #nav-icon3 {
        width: 100%;
        /* height: 100%; */
        position: relative;
        /* margin: 50px auto; */
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
        top:21px;
        z-index:100;
    }
    
    #nav-icon3 span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: #000000;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
        z-index:100;
    }
    
    
    /* Icon 3 */
    
    #nav-icon3 span:nth-child(1) {
        top: 0px;
    }
    
    #nav-icon3 span:nth-child(2) {
        top: 6px;
    }
    
    #nav-icon3.open span:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        /* width:100%; */
        background: #000000;
        z-index:100;
        top:5px
    }
    
    #nav-icon3.open span:nth-child(2) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        /* width:100%; */
        background: #000000;
        z-index:100;
        top: 5px;
    }
}