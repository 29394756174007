.footer_container {
    position:relative;
    background-color: rgb(250, 250, 250);
    /* height:200px; */
    top:0px;
    /* margin-top:25px; */
}

.footer_content_container {
    width: 70vw;
    min-width: 800px;
    margin:auto;
    text-align: center;
    padding:40px 0px 10px;
}

    
.mobile_footer_social_media_container {
    display:none;
}

.footer_feedback_text {
    /* text-align:left; */
    font-size:14px;
    /* margin-top:25px; */
    /* margin-bottom:20px; */
    padding:20px 0px;
    margin:0px;
    border-bottom: solid 1px rgb(230, 230, 230);
    font-weight:400;
}

.footer_feedback_link {
    font-weight:500;
    cursor: pointer;
    text-decoration: none;
    color:black;
}

.footer_feedback_link:hover {
    text-decoration: underline;
}

.footer_recipe_tags {
    /* background-color: pink; */
    font-size:14px;
    padding:30px 0px;
    margin:0px;
    display:flex;
    justify-content: space-between;
    vertical-align: top;
    border-top: solid 1px rgb(230, 230, 230);
    border-bottom: solid 1px rgb(230, 230, 230);
}

.footer_column {
    /* background-color: pink; */
    width:100px;
    display:inline-block
}

.footer_tag_title {
    font-weight:500;
    text-align: left;
    margin:0px;
    padding:4px 0px 4px 0px;
    user-select: none;
}   

.footer_cuisine_items {
    list-style-type: none;
    text-align: left;
    margin:0px;
    padding-top:0px;
}

.footer_tag_item {
    background-color: transparent;
    border-style: none;
    font-family: inherit;
    padding:4px 0px;
    display:block;
    cursor: pointer;
    white-space: nowrap;
    color:black;
}

.footer_tag_item:hover {
    text-decoration: underline;
}

.footer_information {
    /* background-color: pink; */
    margin-top:20px;
}

.footer_information_links {
    text-decoration: none;
    font-size:12px;
    font-weight:500;
    list-style: none;
    padding:0px;
}

.footer_information_links_items {
    display:inline-block;
    padding:0px 20px;
    color:rgb(81, 81, 81);
    cursor: pointer;
}

.footer_information_links_items_hover:hover {
    text-decoration: underline;
}

.footer_information_design {
    font-size:12px;
    font-weight:500;
    display:inline-block;
    color:rgb(81, 81, 81);
}

.footer_language_flag_icon {
    line-height: 0px;
    position:relative;
    display:inline-block;
    font-size: 16px;
    top:2px;
    padding:0px;
}


@media screen and (max-width: 768px) {

    .footer_container {
        position:relative;
        background-color: rgb(250, 250, 250);
        /* height:200px; */
        top:0px;
        margin-top:0px;
    }
    
    .footer_content_container {
        width: 90%;
        min-width: 0px;
        margin:auto;
        text-align: center;
        padding:10px 0px 10px;
    }
    
    .mobile_footer_social_media_container {
        display:block;
        width:100%;
        /* background-color: blue; */
        height:50px;
        padding:10px 0px 10px;
        border-bottom: solid 1px rgb(240, 240, 240);
    }

    .mobile_footer_instagram_link_icon {
        display:inline-block;
        position:relative;
        background-color: transparent;
        top:50%;
        transform: translate(0, -50%);
        left:0px;
        height:40x;
        width:40px;
        padding:0px 10px;
        cursor: pointer;
        -webkit-tap-highlight-color: transparent;
    }

    .mobile_footer_instagram_icon_container:hover svg {
        fill: var(--primary-color);
    }

    .footer_recipe_tags {
        display:none;
    }

    .mobile_footer_information_links_items {
        display:block;
        margin-bottom:10px;
    }

    .footer_information {
        margin-top:0px;
    }

    .footer_information_links_items {
        margin-bottom:10px;
    }

    .footer_tag_item:hover {
        text-decoration:none;
    }

    .footer_information_links_items_hover:hover {
        text-decoration: none;
    }
    

    .footer_language_options {
        display:block;
        background-color: white;
        padding:15px 0px;
        border-radius: 6px;
        /* display:flex; */
        align-items: center;
        text-align: center;
        font-size:16px;
        margin-bottom:15px;
    }

    .footer_language_flag_icon {
        display:inline-block;
        padding-left:3px;
        font-size:28px;
        line-height:0px;
        top:4px;
        position:relative;
    }

    .footer_cookie_policy:hover {
        text-decoration: underline;
    }

    .footer_privacy_policy:hover {
        text-decoration: underline;
    }
}