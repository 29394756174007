.create_parent_container {
    padding:50px 0px 30px;
    background-color: rgb(245, 245, 245);
    font-family: 'Roboto', sans-serif;
}

.create_content_container {
    padding-top:50px;
    width:900px;
    margin:auto;
    /* background-color: pink; */
}

.create_content_background_container {
    background-color: white;
    padding:30px 50px;
}

.create_content_header_container {
    padding:0px 0px 20px;
    /* border-bottom: 1px solid #e6e6e6; */
}

.create_title {
    font-size: 35px;
    font-weight: 500;
    margin:0px;    
    display:inline-block;
}


.create_upload_how_to {
    margin-top:20px;
    line-height:150%;
}

.create_content_image {
    width:100%;
    height:540px;
    border: none;
    cursor:pointer;
    background-color: #e6e6e6;
    border-radius: 12px;
}

.create_content_instructions_container {
    margin:50px 0px 0px;
}

.create_content_image_container {
    margin:0px 0px 40px;
    border-radius: 10px;
    overflow: hidden;
    position:relative;
    height:540px;
}

.create_content_delete_container {
    margin:20px 0px 0px;
}

.create_content_no_access {
    position:relative;
    height:calc(100vh - 50px);
    top:-50px;
}

@media only screen and (max-width: 768px) {
    .create_parent_container {
        padding:50px 0px 10px;
    }

    .create_content_header_container {
        padding:0px 1em 20px;
        /* border-bottom: 1px solid #e6e6e6; */
    }

    .create_content_name_container {
        padding: 0px 1em;
    }

    .create_content_introduction_container {
        padding: 0px 1em;
    }

    .create_content_container {
        padding-top:30px;
        width:100%;
        margin:auto;
    }

    .create_content_background_container {
        padding:30px 0px;
    }

    .create_content_image_container {
        height:400px;
        border-radius:0px;
        margin-bottom:0px;
    }

    .create_content_image {
        height:300px;
        border-radius:0px;
    }

    .create_content_ingredients_container {
        margin-top:2em;
        padding:0px 1em;
        
    }

    .create_content_instructions_container {
        margin-top:2em;
        padding:0px 1em;
    }

    .create_content_no_access {
        height:calc(100vh - 0px);
        top:0px;
    }

    .create_content_delete_container {
        margin:10px 1em 0px;
    }
}