.loading_bar_container {
    /* height: 30px; */
    width: 100%;
    position: relative;
    /* background-color: blue; */
    top: 0px;
    left: 0px;
}

.loading_bar {
    position: relative;
    /* top: 50%; */
    /* transform: translateY(-50%); */
    background-color: rgb(240, 240, 240);
    height: 8px;
    width: 100%;
    border-radius: 5px;
}

.loading_bar_progress {
    position: relative;
    width: 98%;
    height: 100%;
    background-color: var(--primary-color);
    border-radius: 5px;
    animation: loading 45s linear 1;
}

.loading_bar_completed {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translate(0%, -100%);
    background-color: var(--primary-color);
    border-radius: 5px;
    animation: loading_complete 0.5s linear 1;
}

@keyframes loading {
    0% {
        width: 0%;
    }

    80% {
        width: 95%;
    }

    100% {
        width: 98%;
    }
}

@keyframes loading_complete {
    0% {
        width: 0%;
    } 

    100% {
        width: 100%;
    }
}