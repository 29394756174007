/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200'); */

:root {
  --primary-color: green;
  --primary-color-hover: rgb(0, 168, 0);
  --cancel-color: rgb(198, 43, 43);
  --cancel-color-hover: rgb(235, 37, 37);
  --button-radius: 5px;
}

body {
  margin: 0;
  font-family: 'Roboto', 'Arial';
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  border-style: none;
  font-weight: 400;
}

input {
  font-size:14px;
}

h1 {
  font-size:50px;
  font-weight:500;
  font-family:inherit;
}

img[loading="lazy"] {
  border: none;
}
 
h1, h2, h3 {
  font-weight:500;
}