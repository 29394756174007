.email_confirmation_container {
    height:100vh;
    min-height:800px;
    user-select: none;
}

.email_confirmation_background {
    width:100%;
    height:100%;
    object-fit: cover;
    left:0%;
    top:0%;
    /* min-height:120%; */
    position:absolute;
}

.email_confirmation_content_container {
    width:960px;
    margin:auto;
    padding-top:100px;
    text-align: center;
}

.email_confirmation_title {
    position:relative;
    display:block;
    font-size: 28px;
    /* height:100px; */
    font-weight:500;
    /* left:50%; */
    /* transform: translate(-50%,0); */
    text-align: center;
    width:100%;
    padding:20px 0px;
}

.email_confirmation_feature {
    position:relative;
    background-color: rgba(240, 240, 240, 0.8);
    backdrop-filter: blur(10px);
    width:700px;
    margin:10px auto;
    display:flex;
    padding-bottom:10px;
    border-radius: 10px;
    padding:15px 50px 15px 30px;
    top:-100px;
    transition: all 0.4s ease;
    opacity: 0;
}

.email_confirmation_failed {
    position:absolute;
    background-color: rgba(240, 240, 240, 0.8);
    backdrop-filter: blur(10px);
    width:700px;
    margin:10px auto;
    border-radius: 10px;
    padding:15px 30px 15px 30px;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
}

.email_confirmation_feature_active {
    opacity: 1;
    top:0px;
}

.email_confirmation_feature_icon {
    position:relative;
    /* background-color: blue; */
    width:100px;
    height:100px;
    display:inline-block;

}

.email_confirmation_feature_icon_text {
    font-size:100px;
    font-variation-settings:
        'FILL' 0,
        'wght' 100,
        'GRAD' 0,
        'opsz' 48
}

.email_confirmation_feature_text_container {
    position:relative;
    /* background-color: pink; */
    height:100px;
    width:600px;
    font-size:14px;
    margin:0px;
    padding:0px 0px 0px 20px;
    text-align: left;
    display:inline-block;
}

.email_confirmation_feature_text {
    position:relative;
    top:50%;
    transform:translateY(-50%);
}

.email_confirmation_feature_title {
    font-size:20px;
    font-weight:500;
    margin:0px;
    padding:0px;
    text-align: left;
}


.email_confirmation_feature p {
    position:relative;
    text-align:left;
    padding-bottom:0px;
    margin-bottom:0px;
    line-height:150%;
}

@media screen and (max-width: 768px) {

    .email_confirmation_container {
        position:relative;
        height:auto;
        min-height:0px;
        user-select: none;
        overflow: hidden;
        padding-bottom:1em;
    }

    .email_confirmation_image_container {
        position:absolute;
        width:100%;
        height:100%;
    }

    .email_confirmation_background {
        position:absolute;
        object-fit:cover;
        height:100%;
        left:0%;
        /* min-height:120%; */
        
    }

    .email_confirmation_failed {
        position:absolute;
        background-color: rgba(240, 240, 240, 0.8);
        backdrop-filter: blur(10px);
        width:calc(100% - 2em);
        margin:0px auto;
        border-radius: 10px;
        padding:15px 30px 15px 30px;
        box-sizing: border-box;
        left:50%;
        top:50%;
        transform: translate(-50%,-50%);
    }

    .email_confirmation_content_container {
        width:calc(100% - 2em);
        margin:auto;
        padding-top:calc(50px);
        text-align: center;
    }
    

    .email_confirmation_feature {
        position:relative;
        background-color: rgba(240, 240, 240, 0.8);
        backdrop-filter: blur(10px);
        width:100%;
        margin:10px auto;
        display:flex;
        padding-bottom:10px;
        border-radius: 10px;
        padding:1.5em;
        box-sizing: border-box;
        top:-100px;
        transition: all 0.4s ease;
        opacity: 0;
    }

    .email_confirmation_feature_active {
        opacity: 1;
        top:0px;
    }

    .email_confirmation_feature_text_container {
        position:relative;
        /* background-color: pink; */
        height:auto;
        /* width:600px; */
        font-size:14px;
        margin:0px;
        padding:0px;
        text-align: left;
        display:inline-block;
    }

    .email_confirmation_title {
        position:relative;
        display:block;
        font-size: 20px;
        /* height:100px; */
        font-weight:500;
        /* left:50%; */
        /* transform: translate(-50%,0); */
        text-align: center;
        width:100%;
        padding:0px;
    }

    .email_confirmation_feature_icon {
        position:relative;
        /* background-color: blue; */
        width:100px;
        height:auto;
        display:none;
    
    }

    .email_confirmation_feature_icon_text {
        position:absolute;
        top:50%;
        left:0%;
        transform: translate(0%,-50%);
        font-size:30px;
        font-variation-settings:
            'FILL' 0,
            'wght' 100,
            'GRAD' 0,
            'opsz' 48
    }
    
}
