.recipe_versions_container {
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    background-color: rgb(0,0,0,0.5); 
    z-index:100
}

.recipe_versions_content_container {
    position:relative;
    width:500px;
    padding:40px;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    background-color:white;
    border-radius:20px;
}

.recipe_versions_title {
    font-size:36px;
    font-weight:500;
    margin:0px 0px 15px;
}

@media screen and (max-width: 768px) {
    .recipe_versions_content_container {
        width:90%;
    }
}