
.dropdown_container {
    position:absolute;
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    z-index: 1000000;
    margin-top:10px;
    border: 2px solid var(--primary-color);
    min-width:100%;
    box-sizing: border-box;
    font-size:14px;
    font-weight:500;
    color: var(--primary-color);
}


.dropdown_list_container {
    /* display:block; */
    /* position:absolute; */
    list-style-type: none;
    padding:0px;
    text-align: left;
    margin:5px 0px;
    /* z-index:10000000; */
}

.dropdown_list_item {
    padding:10px 20px;
    cursor:pointer;
}

.dropdown_list_item:hover {
    background-color: var(--primary-color);
    color:white;
}