.mobile_search_container {
    position:absolute;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
    cursor: pointer;
}

.mobile_search_icon_container {
    position:absolute;
    height:50px;
    width:50px;
    -webkit-tap-highlight-color: transparent;
    text-align: right;
}

.mobile_search_icon {
    position:relative;
    display:inline-block;
    color:black;
    top:50%;
    transform: translate(0, -50%);
    font-variation-settings:
    'FILL' 0,
    'wght' 500,
    'GRAD' 0,
    'opsz' 40

}

.mobile_search_icon_create {
    position:relative;
    display:inline-block;
    color:rgb(255, 255, 255);
    top:50%;
    transform: translate(0, -50%);
    font-variation-settings:
    'FILL' 0,
    'wght' 500,
    'GRAD' 0,
    'opsz' 40

}

.mobile_search_text_container {
    position:absolute;
    left:calc(-100vw + 100px);
    top:0px;
    height:50px;
    background-color: rgb(255, 255, 255);
    transition: all 0.4s ease;
    /* padding: 0px 1em; */
    display:flex;
    vertical-align: bottom;
    overflow:hidden;
    z-index:1002
}

.mobile_search_active_icon_container {
    position:relative;
    height:50px;
    width:50px;
    top:0px;
    left:0px;
    transition: all 0.4s ease;
    /* background-color: pink; */
}

.mobile_search_text_icon {
    top:50%;
    left:50%;
    /* margin:0px 5px 0px 10px; */
    transform: translate(-50%, -50%);
    display:inline-block;
    position:relative;
    color:black;
    /* background-color: pink; */
}

.mobile_search_text {
    height:50px;
    /* width:50px; */
    /* background-color: green; */
    top:0px;
    left:0px;
    display:inline-block;
    position:relative;
    /* line-height:50px; */
    padding:0px;
    margin:0px;
    transition: all 0.4s ease;
}

.mobile_search_form {
    display:inline-block;
    position:relative;
    top:25px;
}

.mobile_search_text_input {
    border:none;
    background-color: none;
    font-family: 'Roboto', sans-serif;
    font-size:16px;
    margin:0px;
    padding:0px;
    position:relative;
    top:50%;
    transform: translate(0%, -50%);
}

.mobile_search_text_input {
    outline:none;
}

.mobile_search_text_close_container {
    height:50px;
    width:50px;
    right:0px;
    position:absolute;
    transition: all 0.4s ease;
    display:block;
    -webkit-tap-highlight-color: transparent;
}

.mobile_search_text_close_icon {
    color:black;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    position:relative;
    display:inline-block;
    font-variation-settings:
    'FILL' 0,
    'wght' 500,
    'GRAD' 0,
    'opsz' 40

}
