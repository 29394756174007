.admin_panel_container {
    position:fixed;
    background-color: rgb(231, 231, 231);
    width:350px;
    height:100vh;
    right:0px;
    top:0px;
    padding:100px 20px 50px;
    box-sizing: border-box;
    overflow-y: scroll;
}

.admin_panel_container h3 {
    font-weight:500;
}

.admin_show_recipe_button {
    padding:10px 20px;
    font-weight:500;
    margin:20px 0px 10px;
    font-size:18px;
}

.admin_panel_section_name {
    font-size:16px;
    margin:6px 0px;

}

.admin_input {
    /* width:100px; */
    padding:10px 10px;
    margin-bottom:10px;
}

.admin_update_button {
    padding:10px 20px;
    font-weight:500;
    font-size:14px;
    background-color: rgb(211, 211, 211);
}

.admin_update_button:hover {
    background-color: var(--primary-color);
    color:rgb(255, 255, 255);
}

.admin_panel_tag {
    display:inline-block;
    background-color: rgb(211, 211, 211);
    padding:5px 10px;
    margin:3px;
    border-radius:5px;
}
.admin_delete_button {
    margin:3px;
}

.admin_panel_tag_info {
    display:inline-block;
    line-height:0px;
    font-size:14px;
}
