.publish_recipe_parent_container {
    display:inline-block;
}

.publish_recipe_title {
    padding:10px 20px;
    color:white;
    background-color: var(--primary-color);
    font-weight:500;
    border:2px solid var(--primary-color);
}

.publish_recipe_title:hover {
    border: 2px solid var(--primary-color-hover);
    background-color: var(--primary-color-hover);
}

.publish_recipe_content_container {
    width:100vw;
    height:100vh;
    position:fixed;
    top:0px;
    left:0px;
    background-color: rgba(0,0,0,0.5);
    z-index:1000;
}   

.publish_recipe_content {
    width:500px;
    /* height:500px; */
    margin:auto;
    background-color: white;
    padding:40px;
    position:relative;
    top:50%;
    /* left:50%; */
    transform:translate(0,-50%);
    border-radius: 12px;
}

.publish_recipe_content_header_container {
    position:relative;
    width:100%;
    line-height:150%;
}

.publish_recipe_content_header {
    font-size:24px;
    margin:0px;
    margin-bottom:0px;
    display:inline-block;
    line-height:130%;
}

.publish_recipe_content_body_container {
    height:400px;
}

.publish_recipe_content_body {
    height:100%;
    width:100%;
    font-family: 'Roboto', sans-serif;
    font-size:16px;
    padding:20px;
    box-sizing: border-box;
    border: 2px solid var(--primary-color);
    border-radius: 10px;
    font-weight:300;
}

.publish_recipe_content_body:focus {
    outline:none;
}


.publish_recipe_content_header_buttons_container {
    position:relative;
}

.publish_recipe_content_header_button_cancel {
    padding:10px 20px;
    color:white;
    background-color: var(--cancel-color);
    font-weight:500;
    margin-right:10px;
}

.publish_recipe_content_header_button_cancel:hover {
    background-color: var(--cancel-color-hover);
}

.publish_recipe_success_recipe_name {
    font-weight:500;
}   

.publish_recipe_success_url_link {
    padding:10px;
    width:100%;
    box-sizing: border-box;
    margin-bottom:10px;
    border: 2px solid var(--primary-color);
    border-radius: 5px;
}

.publish_recipe_success_url_link:focus {
    outline:none;
}

.publish_recipe_error_message {
    margin:10px 0px 0px;
    font-weight:500;
    font-size:16px;
    color:var(--cancel-color);
}

.publish_recipe_required_message_container {
    position:fixed;
    top:0px;
    left:0px;
    width:100vw;
    height:100vh;
    background-color: rgba(0,0,0,0.5);
    z-index:1000;

}

.publish_recipe_required_message_content_container {
    width:320px;
    /* height:200px; */
    margin:auto;
    background-color: white;
    padding:40px;
    position:relative;
    top:50%;
    transform:translate(0,-50%);
    border-radius: 12px;
}

.publish_recipe_required_message_list {
    padding:15px;
}

.publish_recipe_required_message_item {
    padding:3px 0px;
}

@media only screen and (max-width: 768px) {

    .publish_recipe_required_message_container {
        position:fixed;
        top:0px;
        left:0px;
        width:100vw;
        height:100vh;
        background-color: rgba(0,0,0,0.5);
        vertical-align: bottom;
        transition: all 0.2s ease;
    }

    .publish_recipe_required_message_content_container {
        position:fixed;
        width:100%;  
        background-color: white;
        padding:2em;
        top:auto;
        transform: none;
        /* bottom:0px; */
        transform:none;
        border-radius: 12px 12px 0px 0px;
        box-sizing:border-box; 
        transition: all 0.5s ease;
    }

    .publish_recipe_content {
        width:calc(100% - 2em);
        box-sizing: border-box;
        /* height:500px; */
        margin:auto;
        background-color: white;
        padding:1em;
        position:relative;
        top:50%;
        /* left:50%; */
        transform:translate(0,-50%);
        border-radius: 12px;
    }
    
    .publish_recipe_content_header_container {
        position:relative;
        width:100%;
        line-height:150%;
    }

    .publish_recipe_content_header {
        font-size:28px;
    }

    .publish_recipe_required_message, .publish_recipe_required_message_list {
        font-size:18px;
    }
}