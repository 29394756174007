.sign_up_container {
    width:100%;
    height:100vh;
    overflow:hidden;
    top:0px;
    position:relative;
    min-height:800px;
}

.sign_up_hero_image {
    width:100%;
    height:100%;
    object-fit: cover;
}

.option_container {
    position:absolute;
    background-color: rgba(240, 240, 240, 0.8);
    backdrop-filter: blur(10px);
    width:450px;
    height:500px;
    margin:auto;
    border-radius: 20px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    padding:50px;
    white-space: nowrap;
    overflow: hidden;
}

.option_child_container {
    position:relative;
    width:100%;
    white-space: nowrap;
    transition: all .4s ease;
    height:100%;
}

.option_content_container {
    position:relative;
    /* background-color: rgba(189, 54, 54, 0.8); */
    width:450px;
    height:100%;
    border-radius: 20px;
    display:inline-block;
    margin-right:50px;

}

.option_content_container_absolute {
    position:absolute;
    top:50%;
    transform: translateY(-50%);
    width:100%;
}

.sign_up_content_container {
    position:absolute;
    background-color: rgba(245, 245, 245,0.8);
    backdrop-filter: blur(10px);
    width:450px;
    height:530px;
    margin:auto;
    border-radius: 20px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    padding:50px;
}

.sign_up_title {
    margin: auto;
    /* background-color: pink; */
    padding:0px 0px;
    white-space: normal;
    font-size:28px;
    text-align: center;
}

.sign_up_info {
    margin: 15px auto;
    text-align: center;
    line-height: 150%;
    font-size:16px;
    white-space: normal;
}

.sign_up_form_container {
    padding:0px 20px 20px;
    position:relative;
    width:100%;
    box-sizing: border-box;
}

.sign_up_form {
    margin:auto;
    text-align: center;
}

.sign_up {
    padding:10px;
    margin:2px;
    width: 250px;
    height:20px;
    border-radius: 5px;
    border: 1px solid rgb(199, 199, 199);
}

.sign_up:focus {
    /* border-color: rgb(202, 56, 56); */
    outline: 2px solid var(--primary-color);
}

.sign_up_submit {
    width:250px;
    border-radius: 20px;
    background-color: var(--primary-color);
    color:rgb(235, 235, 235);
    height:40px;
    border-style: none;
    margin:20px 0px 0px 0px;
    cursor:pointer;
    transition: all .4s ease;
    font-weight:500;
    position:relative;
}

.sign_up_submit:hover {
    color:rgb(255, 255, 255);
    box-shadow: 0 0 20px rgb(255, 255, 255);
    transition: all .4s ease;
    background-color: var(--primary-color-hover)
}

.sign_up_submit_deactivate {
    width:170px;
    border-radius: 20px;
    background-color: #b3b3b3;
    color:rgb(219, 219, 219);
    height:40px;
    border-style: none;
    margin:20px 0px 0px 0px;
    cursor:not-allowed;
    transition: all .4s ease;
    font-weight:500;
    position:relative;
}

.sign_up_message {
    /* background-color: green; */
    width:250px;
    margin:auto;
    color:red;
    text-align: center;
    font-size:12px;
    padding:0px 0px 5px;
    white-space: normal;
}

.criteria_unconfirmed {
    position:relative;
    top:8px;
    color:grey;
    margin-left:5px;
    font-variation-settings:
    'FILL' 0,
    'wght' 300,
    'GRAD' 0,
    'opsz' 48
}

.go_to_log_in_message {
    width:250px;
    margin:auto;
    color:rgb(34, 34, 34);
    text-align: center;
    font-size:14px;
}

.log_in_here_button {
    background-color: transparent;
    border-style: none;
    font: inherit;
    border-radius:20px;
    padding:5px 10px 5px 5px;
    color:rgb(51,102,204);
    cursor: pointer;
    font-weight:400;
}

.log_in_here_button:hover {
    /* background-color: rgb(255, 255, 255); */
    /* text-decoration: underline; */
    color: rgb(3, 68, 199);
}

.forgot_password {
    background-color: transparent;
    display:block;
    margin:5px auto 10px auto;
    border-style: none;
    font: inherit;
    font-size:14px;
    color:rgb(51,102,204);
    cursor: pointer;
    font-weight:400;
}

.forgot_password:hover {
    color: rgb(0, 70, 209);
}

.privacy_policy_message {
    font-size:12px;
    color:rgb(105, 105, 105);
    text-align:center;
    /* margin:0px; */
    cursor: normal;
    white-space: normal;
    font-weight:400;
}

.privacy_policy_link {
    font-weight:500;
    text-decoration: none;
    color:rgb(105, 105, 105);
    
}

.privacy_policy_link:hover {
    text-decoration: underline;
    color:rgb(105, 105, 105);
}

.privacy_policy_link:visited {
    color:rgb(105, 105, 105);
}

.marketing_message_parent_container {
    position:relative;
    top:12px;
    -webkit-tap-highlight-color: transparent;
    text-align: left;
    left:50px;
}

.marketing_message_container {
    position: relative;
    cursor: pointer;
    user-select: none;
}

.marketing_message_checkbox {
    position: relative;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
  
.marketing_message_checkmark {
    position: relative;
    top: 6px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #ffffff;
    border-radius: 5px;
    display:inline-block;
    margin-right:10px;
}

/* When the checkbox is checked, add a blue background */
.marketing_message_container input:checked ~ .marketing_message_checkmark {
    background-color: green;
}

/* Create the checkmark/indicator (hidden when not checked) */
.marketing_message_checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.marketing_message_container input:checked ~ .marketing_message_checkmark:after {
    display: block;

}

/* Style the checkmark/indicator */
.marketing_message_container .marketing_message_checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.marketing_message_text {
    display: inline-block;
    font-size: 14px;
    color: #313131;
    margin:0px;
    line-height:0px;
    
}

.sign_up_terms_of_use {
    color:black;
    text-decoration: none;
    font-weight:500;
}

.sign_up_terms_of_use:hover {
    text-decoration: underline;
}

@media screen and (max-width: 768px) {

    .sign_up_container {
        width:100%;
        height:auto;
        overflow:hidden;
        top:0px;
        position:relative;
        min-height:750px;
    }

    .sign_up_form {
        width: 100%;
        
    }

    .option_container {
        position:absolute;
        background-color: rgb(240, 240, 240);
        backdrop-filter: blur(10px);
        width:100vw;
        height:100vh;
        margin:auto;
        border-radius: 20px;
        top:10px;
        left:50%;
        transform: translate(-50%,0%);
        padding:50px;
        white-space: nowrap;
        overflow: hidden;
    }

    .sign_up_hero_image {
        display: none;
    }

    .option_content_container {
        position:relative;
        /* background-color: rgba(189, 54, 54, 0.8); */
        width:100vw;
        height:100%;
        /* border-radius: 20px; */
        display:inline-block;
        margin-right:0px;
        /* padding:20px; */
        box-sizing: border-box;
    
    }

    .sign_up_content_container {
        width:100%;
    }

    .option_content_container_absolute {
        position:absolute;
        top:0%;
        transform: translateY(0%);
        width:100%;
    }
    
    .sign_up_title {
        margin: 25px;
        /* background-color: pink; */
        padding:0px 0px;
        white-space: normal;
        font-size:28px;
        text-align: center;
        color:black;
    }

    .sign_up_info {
        margin: 25px;
        text-align: center;
        line-height: 150%;
        font-size:16px;
        white-space: normal;
    }

    .sign_up {
        padding:10px 40px 10px 10px;
        margin:2px;
        width: calc(100% - 60px);
        height:30px;
        border-radius: 5px;
        border: 1px solid rgb(160, 160, 160);
        font-size:16px;
    }

    .sign_up:focus {
        border: 2px solid var(--primary-color);
        outline: none;
    }

    .sign_up_submit {
        width:250px;
        border-radius: 30px;
        background-color: var(--primary-color);
        color:rgb(235, 235, 235);
        height:50px;
        border-style: none;
        margin:20px 0px 0px 0px;
        cursor:pointer;
        transition: all .4s ease;
        font-weight:500;
    }
    
    .sign_up_submit:hover {
        color:rgb(255, 255, 255);
        box-shadow: 0 0 20px rgb(255, 255, 255);
        transition: all .4s ease;
        background-color: var(--primary-color-hover)
    }
    
    .sign_up_submit_deactivate {
        width:170px;
        border-radius: 30px;
        background-color: #b3b3b3;
        color:rgb(219, 219, 219);
        height:50px;
        border-style: none;
        margin:20px 0px 0px 0px;
        cursor:not-allowed;
        transition: all .4s ease;
        font-weight:500;
    }

    .criteria_unconfirmed {
        position:absolute;
        top:16px;
        right:10px;
        z-index:100;
        color:grey;
        margin-left:5px;
        font-variation-settings:
        'FILL' 0,
        'wght' 300,
        'GRAD' 0,
        'opsz' 48
    }

    .privacy_policy_message {
        margin:5px 25px;
        box-sizing: border-box;
        white-space: normal;
    }

}