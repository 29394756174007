.placeholder-container {
    position: relative;
    background-color: rgb(240, 240, 240);
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
}

.loading-bar {
    position: absolute;
    top: 0;
    left: -100%;
    width: 75%;
    height: 100%;
    background: linear-gradient(to right, rgb(240, 240, 240), rgb(255, 255, 255), rgb(240, 240, 240));
    animation: loading-animation 2s infinite;
}

@keyframes loading-animation {
    0% {
        left: -100%;
    }

    100% {
        left: 100%;
    }
}