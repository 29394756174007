.create_upload_new_user_message {
    padding:10px 20px;
    width:100%;
    background-color: var(--cancel-color);
    box-sizing: border-box;
    border-radius: 5px;
    color:white;
    text-align: center;
    font-weight:500;
    margin-bottom:25px;
    margin-top:0px;
    cursor:pointer;
}

.create_upload_new_user_message:hover {
    background-color: var(--cancel-color-hover);
}

.create_upload_recipe_container {
    float:right;
}


.create_upload_recipe_title_placeholder {
    width:100%;
    height:50px;
}

.create_upload_recipe_body_placeholder {
    width:100%;
    height:90px;
    margin-top:20px;
}


@media only screen and (max-width: 768px) {
    .create_upload_new_user_message {
        margin-bottom:10px;
    }

    .create_upload_recipe_container {
        position:relative; 
        display:grid;
        grid-template-columns: 1fr 1fr;
        float:none;
        column-gap: 5px;
    }
}