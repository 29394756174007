.recipe_instructions_container {
    padding:0px 0px 35px;
}

.recipe_instructions_title {
    display:inline-block;
    position:relative;
    font-weight:500;
    font-size:28px;
    margin:0px 0px 20px 0px;
}


.recipe_instructions_cooking_time {
    position:relative;
    top:-2px;
    font-size:14px;
    background-color: rgb(240, 240, 240);
    padding:10px 15px;
    border-radius: 7px;
    float:right;
    font-weight:400;
}

.hourglasss_icon {
    position:relative;
    top:5px;
    left:-5px;
    color:rgb(61, 61, 61);
    font-size:20px;
    line-height:0px;
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
}

.recipe_instructions_item {
    padding:0px 0px 20px;
    list-style-type: none;
    margin-left: 0px;
    line-height:150%;
    cursor: pointer;
}

.recipe_steps_text {
    position:relative;
    font-weight:500;
    width:10%;
    margin:0px;
    top:0px;
    vertical-align:top;
    display:inline-block;
}

.recipe_instruction_text {
    display:inline-block;
    width:90%;
}

.recipe_instruction_tip {
    font-size:14px;
    margin:0px;
    color:grey;
}

@media screen and (max-width: 768px) {
    .recipe_instructions_container {
        background:white;
        padding:35px 1em 20px;
        font-size:18px;
        margin-bottom:15px;
    }
    .recipe_steps_text {
        /* width:15%; */
        width:100%;
    }

    .recipe_instruction_text {
        display:block;
        width:100%;
    }

    .recipe_instructions_title {
        font-size:1.5em;
        /* padding-bottom:20px; */
    }

}

