.recipe_image_container {
    margin:0px 0px 40px;

    /* border-bottom: solid 1px rgb(240, 240, 240); */
}

.recipe_image {
    margin:0px 0px 0px 0px;
    padding:0px;
    width:100%;
    height:540px;
    border-radius: 12px 12px 0px 0px;
    object-fit: cover;
    background-color: rgb(255, 255, 255);
}

.recipe_image_disclaimer{
    font-size: 14px;
    color:grey;
    margin:0px;
    padding:20px;
}

.recipe_image_child_container {
    position:relative;
    /* border: solid 15px rgb(255, 255, 255); */
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    border-radius: 12px;
    overflow: hidden;
    height:540px;
}
    
.recipe_image_favourite_button_container {
    position:absolute;
    top:10px;
    right:10px;
    /* margin:2em; */
    /* background-color: pink; */
}

@media screen and (max-width: 768px) {


    .recipe_image_container {
        position:relative;
        padding:0px 0px 0px;
        background-color: white;
        margin-bottom:0px;
        border-bottom: none;
    }

    .recipe_image {
        margin:15px 0px 0px 0px;
        position:relative;
        height:100%;
        border-radius: 0px;
    }

    .recipe_image_disclaimer{
        font-size: 14px;
        color:grey;
        margin:15px 0px 5px ;
        padding:0px;
    }
    
    .recipe_image_favourite_button_container {
        position:absolute;
        top:28px;
        right:13px;
        /* margin:2em; */
    }
    .recipe_image_child_container {
        position:relative;
        border: none;
        box-shadow: none;
        border-radius: 0px;
        margin:0px;
        padding:0px;
        height:100vw;
    }

}