.create_tmp_recipe_container {
    position:fixed;
    top:0px;
    left:0px;
    height:100vh;
    width:100vw;
    background-color: rgb(0,0,0,0.5);
    z-index:10;
}

.create_tmp_recipe_content_container {
    width:400px;
    position:relative;   

}

.create_tmp_recipe_title {
    margin:0px;
    font-size:42px;
}

.create_tmp_recipe_info {
    line-height:150%;
}

@media screen and (max-width: 768px) {
    .create_tmp_recipe_info_button_container {
        display:grid;
        grid-template-columns: 1fr 1fr;
        grid-gap:5px;
    }
}