
.create_content_introduction {
    font-size:18px;
    line-height: 150%;
    width:calc(100% + 40px); 
    font-family: 'Roboto', sans-serif;
    font-weight:300;
    /* height:150px; */
    resize: none;
    height: auto;
    /* min-height:1em; */
    /* box-sizing: border-box; */
    left:-20px;
    /* top:-20px; */
    position:relative;
    border: 2px solid white;
    transition: all 0.4s ease-in-out;
    box-sizing: border-box;
    border-radius: 5px;
    padding:20px;
    top:-20px;
    margin-bottom:0px;
}

.create_content_introduction:hover {
    cursor:pointer;
}

.create_content_introduction:focus {
    top:0px;
    outline: none;
    background-color: rgb(240,240,240);
    cursor: text;
    margin-bottom:20px;
}

@media only screen and (max-width: 768px) {

    .create_content_introduction {
        font-size:18px;
        line-height: 150%;
        width:100%;
        border: none;
        font-family: 'Roboto', sans-serif;
        font-weight:300;
        /* height:150px; */
        resize: none;
        height: auto;
        /* min-height:1em; */
        /* box-sizing: border-box; */
        left:0px;
        /* top:-20px; */
        position:relative;
        border: transparent;
        transition: all 0.4s ease-in-out;
        box-sizing: border-box;
        border-radius: 0px;
        padding:0px;
        top:0px;
        margin-bottom:10px;
        touch-action: manipulation;
        overflow:hidden;
    }

    .create_content_introduction:focus {
        top:0px;
        outline: none;
        background-color: transparent;
        cursor: text;
        margin-bottom:10px;
    }
}