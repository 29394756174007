 .mobile_pop_up_mask {
    position:fixed;
    top:0px;
    left:0px;
    width:100vw;
    height:100vh;
    background-color: rgba(0,0,0,0.5);
    z-index:1003; 
    
}

.mobile_pop_up_content_container {
    position:fixed;
    bottom:0px;
    left:0px;
    width:100%;
    background-color: white;
    padding:1.5em;
    box-sizing: border-box;
    border-radius:12px 12px 0px 0px;
    transition: all 0.3s ease-in-out;
    z-index:1004;
    max-height:80%;
    /* overflow:scroll; */
}

.mobile_pop_up_title {
    margin:5px 0px 15px;
    font-size:28px;
}

.mobile_pop_up_body {
    white-space: wrap;
    overflow: scroll;
}

.mobile_pop_up_close_button_container {
    height:20px;
    width:70px;
    /* background-color: red; */
    position:absolute;
    top:0px;
    left:50%;
    transform: translate(-50%, 0%);
}

.mobile_pop_up_close_button {
    position:relative;
    margin-top:8px;
    height:5px;
    background-color:rgb(225, 225, 225);
    border-radius:5px;
}

.desktop_pop_up_mask {
    position:fixed;
    top:0px;
    left:0px;
    width:100vw;
    height:100vh;
    background-color: rgba(0,0,0,0.5);
    z-index:1003; 
    
}

.desktop_pop_up_content_container {
    position:fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width:600px;
    background-color: white;
    padding:40px;
    box-sizing: border-box;
    border-radius:20px;
    z-index:1004;
    max-height:80%;
    overflow:auto;
}

.desktop_pop_up_title {
    margin:0px 0px 15px;
    font-size:28px;
}

.desktop_pop_up_content_header {
    position:relative;
    /* background-color: blue; */
}

.desktop_pop_up_close_button_container {
    /* height:100%; */
    width:auto;
    /* background-color: red; */
    position:absolute;
    top:-25px;
    right:-25px;
    color:rgb(205, 205, 205);
}

.desktop_pop_up_close_button_container:hover {
    cursor:pointer;
    color:var(--cancel-color)
}

.desktop_pop_up_close_button {
    position:relative;
    /* background-color: pink; */
    /* top:50%; */
    /* transform: translate(0%,-50%); */
    font-size:28px;
    font-variation-settings:
    'FILL' 0,
    'wght' 600,
    'GRAD' 0,
    'opsz' 48
}
 