.shopping_list_container {
    position:relative;
    margin: 0px auto;
    min-height:calc(100vh - 50px);
}

.shopping_list_background {
    position:absolute;
    height:100%;
    width:100%;
    background-image: url('https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/shopping_list.jpg');
    background-repeat:repeat;
    opacity: 0.2;
}

.shopping_list_content_container {
    position:relative;
    width: 960px;
    margin:auto;
    min-height:calc(100vh - 50px); 
    padding-bottom:50px;
    box-sizing: border-box;
}

.shopping_list_content_child_container {
    padding-top: 100px;
    position:relative;
}

.shopping_list_header {
    /* background-color: red; */
    margin:0px;
    font-size: 28px;
    font-weight:500;
    line-height:normal;
}

.shopping_list_list {
    margin:0px;
    padding: 0;
    list-style-type: none;
}

.shopping_list_item {
    background-color: rgb(235, 235, 235);
    position:relative;
    border-radius: 5px;;
    padding:10px 20px;
    margin:5px 0px;
    list-style: none;
    transition: all .4s ease;
    font-weight:500;
}

.shopping_list_item:hover {
    background-color: rgb(212, 212, 212);
    transition: all .4s ease;
}

.shopping_list_item_completed {
    background-color: var(--primary-color);
    border-radius: 5px;;
    padding:10px 20px;
    margin:5px 0px;
    list-style: none;
    transition: all .4s ease;
    font-weight:500;
    color:white;
}

.shopping_list_item_completed:hover {
    background-color: rgb(110, 204, 122);
    transition: all .4s ease;
}

.shopping_list_measurement_name {
    position:relative;
    /* background-color: pink; */
    float:right;
}

.empty_shopping_list_container {
    position:relative;
    height:calc(100vh - 50px);
    width:100%;
}

.empty_shopping_list {
    position:relative;
    /* background-color: red; */
    height:300px;
    width:300px;
    top:45%;
    left:50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color:rgb(128, 128, 128);
    font-weight:400;
}

.empty_shopping_cart {
    font-size:200px;
    color:grey;
}

.explore_recipes {
    background-color: var(--primary-color);
    color:white;
    font-weight:500;
    padding:10px 20px;
}

.explore_recipes:hover {
    background-color: var(--primary-color-hover);
}

.empty_shopping_image {
    width:100%;
}

@media screen and (max-width: 768px) {
    
    .shopping_list_content_container {
        position:relative;
        width: 100%;
        margin:auto;
        min-height:100vh; 
        padding-bottom:50px;
    }

}