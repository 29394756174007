.settings_container {
    position:relative;
    padding:50px 0px 50px;
    
}

.settings_container_background {
    position:absolute;
    width:100%;
    height:calc(100% + 50px);
    /* z-index:-1; */
    background-image: url('https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/shopping_list.jpg');
    background-repeat:repeat;
    opacity: 0.2;
    top:-50px;
}

.settings_header_title {
    font-size:42px;
}

.settings_content {
    position:relative;
    width:700px;
    margin:auto;
}

.settings_body_item {
    position: relative;
    width:100%;
    /* border-top: 1px solid rgb(240, 240, 240); */
    background-color: rgb(240, 240, 240);
    padding: 0px 20px;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom:5px;

    cursor:pointer;
}

.settings_body_item_header {
    position:relative;
    display:inline-block;
    left:0px; 
}

.settings_body_item_content {
    position:relative;
    float:right;
    
}

.settings_item_value {
    font-weight:500;
    display:inline-block;
    margin-left:10px;
}

.settings_item_edit_button {
    display:none;
}

.settings_body_item:hover .settings_item_edit_button {
    display:inline-block;
}

.settings_body_item_editable:hover {
    background-color: rgb(230, 230, 230);
}