.loader_container {
  position:absolute;
  width:100%;
  background-color:inherit;
  height:100%;
  top:0px;
  left:0px;
  border-radius: inherit;
  border: inherit;
  box-sizing: border-box
}

.loader_center_container {
  width: 15px;
  height: 15px;
  /* background-color: pink; */
  top:50%;
  left:50%;
  position:absolute;
  transform: translate(-50%, -50%);
}

.loader {
  top:-3px;
  left:-3px;
  position:relative;
  border: 3px solid #f3f3f3;
  border-top: 3px solid var(--primary-color);
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 2s linear infinite;
  /* background-color: pink; */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
