.recipes_item_container {
    position:relative;
    background-color: white;
    width:100%;
    height:60px;
    border-radius: 7px;
    padding:10px 20px;
    box-sizing: border-box;
    margin-bottom:10px;
}

.recipes_item_info_container {
    /* background-color: pink; */
    height:25px;
    margin-bottom:10px;
}

.recipes_item_info_left_side {
    /* background-color: green; */
    display:inline-block;
    height:100%;
    /* width:100px; */
}

.recipes_item_info_right_side {
    height:100%;
    float:right;
    
}

.recipes_item_name {
    margin:0px;
    height:25px;
    position:relative;
    cursor:pointer;
    font-weight:500;
    display:inline-block;
    vertical-align: top;
    top: 3px;
    margin-left:20px;
}

.recipes_item_name:hover {
    color:var(--primary-color);
    font-weight:500;
}

.recipes_item_serving_text {
    margin:0px;
    height:25px;
    /* padding:0px; */
    /* height:50px; */
    display:inline-block;
    top:-2px;
    position:relative;
    user-select: none;
    font-weight:500;
}

.recipes_item_servings {
    display:inline;
    height:100%;
    float:right;
}

.recipes_item_remove {
    position:relative;
    height:100%;
    width:20px;
    display:inline-block;
}

.recipes_item_status_container{
    /* background-color: grey; */
    width:100%;
    height:5px;
    display:flex;
    justify-content:space-between;
}

.recipes_item_status_bar {
    background-color: var(--primary-color);
    display: inline-block;
    height:5px;
    position:relative;
}

.recipes_item_close {
    position:relative;
    background-color: transparent;
    height:10px;
    width:10px;
}

.recipes_item_close:hover {
    color:black;
}

.recipe_item_serving_adjust_button_minus {
    margin-right:10px;   
    background: transparent;
    width:25px;
    height:25px;
    background-color: rgb(245, 245, 245);
    color:grey;
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48

}

.recipe_item_serving_adjust_button_minus:hover {
    background-color: var(--primary-color);
    color:white;

}

.recipe_item_serving_adjust_button_add {
    margin-left:10px;
    background: transparent;
    width:25px;
    height:25px;
    background-color: rgb(245, 245, 245);
    color:grey;
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 48
}

.recipe_item_serving_adjust_button_add:hover {
    background-color: var(--primary-color);
    color:white;
}

.recipes_item_remove_button {
    position:absolute;
    height:25px;
    width:25px;
    display:inline;
    border-radius: 3px;
    background-color: rgb(245, 245, 245);
    /* background-color: transparent; */
    color:rgb(203, 203, 203);
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
}

.recipes_item_remove_button:hover {
    background-color: grey;
    color:white;
}

.recipes_item_remove_icon {
    display:inline;
    position:absolute;
    left:3px;
    font-size:20px;
    line-height:0px;
    margin:0px;
    padding:0px;
}

@media screen and (max-width: 768px) {

    .recipes_item_info_left_side {
        /* background-color: green; */
        display:inline-block;
        height:100%;
        
    }
    
    .recipes_item_info_right_side {
        height:100%;
        position:absolute;
        right:9px;
        top:10px;
        float:none;

    }
    
    .recipes_item_name {
        display:inline-block;
        /* background-color: blue; */
        vertical-align: top;
        top:3px;
        margin-left:10px;
    }

    .recipes_item_serving_text {
        margin:0px;
        height:25px;
        /* padding:0px; */
        line-height:0px;
        display:inline-block;
        top:-8px;
        position:relative;
        user-select: none;
        font-weight:500;
    }
    

    .servings_person_icon {
        position:relative;
        top:6px;
        margin:0px;
        font-variation-settings:
        'FILL' 0,
        'wght' 300,
        'GRAD' 0,
        'opsz' 48
    }

    
    .recipe_item_serving_adjust_button_minus {
        margin-right:0px;   
        background: transparent;
        width:25px;
        height:25px;
        /* background-color: rgb(245, 245, 245); */
        color:rgb(203, 203, 203);
        font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 48

    }

    .recipe_item_serving_adjust_button_minus:hover {
        background-color: transparent;
        color: var(--primary-color);
    }

    .recipe_item_serving_adjust_button_add {
        margin-left:0px;
        background: transparent;
        width:25px;
        height:25px;
        position:relative;
        right:5px;
        /* background-color: rgb(245, 245, 245); */
        color:rgb(203, 203, 203);
        font-variation-settings:
            'FILL' 0,
            'wght' 400,
            'GRAD' 0,
            'opsz' 48
    }

    .recipe_item_serving_adjust_button_add:hover {
        background-color: transparent;
        color: var(--primary-color);
    }


    .recipes_item_remove {
        position:relative;
        height:100%;
        width:25px;
        display:inline-block;
        box-sizing: border-box;
        /* background-color: pink; */
        margin:0px;
        padding:0px;
        line-height:0px;
    }

    .recipes_item_remove_button {
        position:absolute;
        height:25px;
        width:25px;
        margin-left:0px;
        display:inline;
        border-radius: 5px;
        background-color: rgb(245, 245, 245);
        /* background-color: transparent; */
        color:rgb(131, 131, 131);
        font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 48
    }
    
    .recipes_item_remove_button:hover {
        /* background-color: grey; */
        color:white;
    }

    .recipes_item_remove_icon {
        display:inline;
        position:absolute;
        left:4px;
        font-size:20px;
        line-height:0px;
        margin:0px;
        padding:0px;
    }
}