.recipes_list_container {
    background-color: rgb(245, 245, 245);
    
    width:100%;
    border-radius: 10px;
    padding:10px 20px;
    box-sizing: border-box;
    margin-bottom:15px;
    box-shadow: rgb(185, 185, 185) 0px 0px 20px;
}

.recipes_list_title {
    margin:10px 10px 20px 0px;
    font-weight:500;
    font-size:18px;
}

@media screen and (max-width: 768px) {
    .recipes_list_container {
        width:100%;
        height:100%;
        border-radius: 0px;
        padding:10px 1em;
        box-sizing: border-box;
        display:flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
}