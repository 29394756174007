.feedback_container {
    background-color: rgb(241, 241, 241);
    height:100vh;
}

.feedback_background_image {
    position:absolute;
    top:0px;
    width:100%;
    height:100%;
    object-fit: cover;
}

.feedback_content {
    position:relative;
    top:50%;
    transform: translateY(-50%);
    width:750px;
    margin:auto;
    background-color: white;
    border-radius: 10px;
    padding:50px;
    line-height:150%;
    z-index: 1000;
    background-color: rgba(240, 240, 240, 0.8);
    backdrop-filter: blur(10px);
}

.feedback_content_title{
    font-size: 36px;
    font-weight: 500;
    line-height: 120%;
}

.feedback_text {
    font-size: 18px;
}

.feedback_email_and_name_input_container {
    display:flex;
    justify-content: space-between;
}

.feedback_email_input_container {
    display:inline-block
}

.feedback_form_input_email {
    width: 340px;
    height: 40px;
    border-radius: 5px;
    border: solid 1px rgb(228, 228, 228);
    padding: 10px;
    font-size: 16px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.feedback_form_input_email:focus {
    outline: 1px solid var(--primary-color);
}

.feedback_name_input_container {
    display:inline-block;
}

.feedback_form_input_name {
    width: 340px;
    height: 40px;
    border-radius: 5px;
    border: solid 1px rgb(228, 228, 228);
    padding: 10px;
    font-size: 16px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.feedback_form_input_name:focus {
    outline: 1px solid var(--primary-color);
}

.feedback_form_input_feedback {
    width: 100%;
    height: 200px;
    border-radius: 5px;
    border: solid 1px rgb(228, 228, 228);
    padding: 10px;
    font-size: 16px;
    margin-bottom: 10px;
    white-space: pre-wrap;
    font-family: inherit;
    box-sizing: border-box;
    resize:none;
}

.feedback_form_input_feedback:focus {
    outline: 1px solid var(--primary-color);
}

.feedback_form_submit {
    /* width: 100px; */
    /* height: 30px; */
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    margin-bottom: 10px;
    background-color:var(--primary-color);
    cursor: pointer;
    color:white;
    font-weight:500;
}

.feedback_form_submit:hover {
    background-color:var(--primary-color-hover);
}

.feedback_form_success {
    margin:0px;
}

@media screen and (max-width: 768px) {
    .feedback_content {
        width: 90%;
        padding: 20px;
        padding-top:100px;
        backdrop-filter: blur(10px);
        border-radius: 0px
    }

    .feedback_content_title {
        font-size: 24px;
        margin-top:30px;
    }

    .feedback_text {
        font-size: 16px;
    }

    .feedback_email_and_name_input_container {
        flex-direction: column;
    }

    .feedback_email_input_container {
        margin-bottom: 20px;
    }

    .feedback_form_input_email {
        width: 100%;
        margin-bottom: 0px;
    }

    .feedback_name_input_container {
        margin-bottom: 20px;
    }

    .feedback_form_input_name {
        width: 100%;
    }

    .feedback_form_input_feedback {
        width: 100%;
    }
}
