.recipe_variations_container {
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    /* background-color: rgb(0,0,0,0.5);  */
    z-index:1000
}

.recipe_variations_content_container {
    position:relative;
    width:800px;
    padding:40px;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    background-color:white;
    border-radius:20px;
}

.recipe_variations_title {
    font-size:36px;
    font-weight:500;
    margin:0px 0px 15px;
}

.table_header {
    font-weight:500;
}

.recipe_variations_recipe_container {
    padding:15px;
    background-color: rgb(240,240,240);
    margin-bottom:5px;
    border-radius: 5px;
    cursor: pointer;
}

.recipe_variations_recipe_container:hover {
    background-color: rgb(230, 230, 230);
}

.recipe_variations_recipe_name {
    font-weight:500;
    width:100%;
    line-height:22px;
    font-size:18px;
    white-space: nowrap;
    /* overflow:scroll; */
}

.recipe_variation_item_info {
    white-space: nowrap;
    /* overflow:scroll; */
}

.recipe_variations_recipe_rating {  
    display:inline-block;
    font-size:14px;
}

.recipe_variations_recipe_views {  
    display:inline-block;
    font-size:14px;
}

.recipe_variations_recipe_date_published {  
    display:inline-block;
    font-size:14px;
}

.recipe_variation_row {
    cursor:pointer;
}

.recipe_variation_row:hover {
    text-decoration: underline;
}

@media screen and (max-width: 768px) {
    .recipe_variations_content_container {
        width:90%;
    }

    .recipe_variations_title {
        font-size:28px;
        font-weight:500;
        margin:0px 0px 15px;
    }

    .recipe_variations_recipe_content_container {
        overflow:scroll;
        white-space: nowrap;
    }
}