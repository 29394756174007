.create_content_ingredients_list_container {
    /* background-color: rgb(240, 240, 255); */
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
}

.create_ingredient_tile_container {
    position:relative;
    width:100%;
    height:100%;
}

.create_ingredient_tile_edit_container {
    position:absolute;
    background-color: transparent;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
    border-radius: 7px;
    /* transition: all 0.4s ease; */
    background-color: rgba(255, 255, 255, 0);
    text-align: center;
    opacity:0;
    /* display:none; */
}

.create_ingredient_tile_edit_container:hover {
    background-color: rgba(255, 255, 255, 0.8);
    /* display:inline-block; */
    opacity:1;
}

.create_ingredient_tile_edit_content {
    position:relative;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    padding:10px;
}

.create_ingredient_tile_update_button {
    position:relative;
    display:block;
    background-color: var(--primary-color);
    width:calc(100% - 20px);
    color:white;
    font-weight:500;
    padding:10px;
    margin:auto auto 5px auto;
    /* opacity: inherit; */
}

.create_ingredient_tile_delete_button {
    position:relative;
    display:block;
    background-color: rgb(200, 61, 61);
    width:calc(100% - 20px);
    color:white;
    font-weight:500;
    padding:10px;
    margin:auto;
}

@media only screen and (max-width: 768px) {
    .create_content_ingredients_list_container {
        grid-template-columns: 1fr 1fr 1fr;
    }
}