.carousel_container {
    position:relative;
}

.carousel_content_container {
    position: relative;
    width:85vw;
    min-width:768px;
    max-width:1400px;
    height:300px;
    padding:60px 50px 25px 50px;
    margin:auto;
    white-space: nowrap;
    overflow: hidden;
}

.carousel_title {
    font-weight: 500;
    font-size:16px;
    position:absolute;
    top:20px;
    margin:0px;
}

.carousel_multi_group_container {
    position:relative;
    height:100%;
}

.carousel_single_group_container {
    /* background-color: rgb(32, 34, 167); */
    display:inline-flex;
    justify-content: space-between;
    width:100%;
    height:100%;
    transition: all .4s ease;
    margin-right:10%;
}

.carousel_small_left_button {
    background-color: rgb(163, 43, 43);
    background-color: transparent;
    position:absolute;
    color:rgb(185, 185, 185);
    border-style:none;
    width:50px;
    height:calc(100% - 85px);
    top:calc(60px);
    transform: translate(-100%,0%);
    user-select: none;
    cursor: pointer;
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
}

.carousel_small_left_button:hover {
    color:rgb(110, 110, 110);
}

.carousel_small_right_button {
    /* background-color: rgb(185, 20, 20); */
    background-color: transparent;
    position:absolute;
    color:rgb(185, 185, 185);
    border-style:none;
    width:50px;
    height:calc(100% - 85px);
    top:calc(60px);
    right:0px;
    user-select: none;
    cursor: pointer;
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
}

.carousel_small_right_button:hover {
    color:rgb(110, 110, 110);
}

.carousel_arrow_icon {
    font-size:30px;
}

.carousel_button_container {
    display:block;
}

@media screen and (max-width: 768px) {
    .carousel_container {
        position:relative;
    }
    
    .carousel_content_container {
        position: relative;
        width:100vw;
        min-width:0px;
        max-width:1400px;
        height:500px;
        padding:60px 0px 25px 0px;
        margin:auto;
        white-space: nowrap;
        overflow: hidden;
        
    }
    
    .carousel_title {
        font-weight: 500;
        position:absolute;
        top:20px;
        margin:0px;
        left:20px;
    }
    
    .carousel_multi_group_container {
        position:relative;
        height:100%;
        overflow-x: scroll;
        overflow-y:hidden;
        scroll-snap-type: x mandatory;

    }
    
    .carousel_single_group_container {
        /* background-color: rgb(32, 34, 167); */
        display:inline-block;
        position:relative;
        width:100%;
        height:100%;
        transition: all .4s ease;
        left:1em;
    }
    
    .carousel_small_left_button {
        background-color: rgb(163, 43, 43);
        background-color: transparent;
        position:absolute;
        color:rgb(185, 185, 185);
        border-style:none;
        width:50px;
        height:calc(100% - 85px);
        top:calc(60px);
        transform: translate(-100%,0%);
        user-select: none;
        cursor: pointer;
        font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 48
    }
    
    .carousel_small_left_button:hover {
        color:rgb(110, 110, 110);
    }
    
    .carousel_small_right_button {
        /* background-color: rgb(185, 20, 20); */
        background-color: transparent;
        position:absolute;
        color:rgb(185, 185, 185);
        border-style:none;
        width:50px;
        height:calc(100% - 85px);
        top:calc(60px);
        right:0px;
        user-select: none;
        cursor: pointer;
        font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 48
    }
    
    .carousel_small_right_button:hover {
        color:rgb(110, 110, 110);
    }
    
    .carousel_arrow_icon {
        font-size:30px;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    .carousel_button_container {
        display:none;;
    }
} 