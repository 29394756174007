.log_in_sign_up_container {
    position:relative;
    float:right;
    height:100%;
    background-color: transparent;
}

.log_in_button_container {
    display:inline-block;
    background-color: transparent;
    height:100%;
    vertical-align: middle;
}

.log_in_button {
    position:relative;
    background-color:transparent;
    height:40px;
    border-style: none;
    margin:0px 10px;
    font-family:inherit;
    font-weight:inherit;
    font-size:inherit;
    color:rgb(124, 124, 124);
    cursor:pointer;
    padding:10px 15px;
    top:50%;
    transform: translate(0, -50%);
}

.log_in_button:hover {
    color:var(--primary-color);
}

.log_in_button_create {
    position:relative;
    background-color:transparent;
    height:40px;
    border-style: none;
    margin:0px 10px;
    font-family:inherit;
    font-weight:inherit;
    font-size:inherit;
    color:rgb(255, 255, 255);
    cursor:pointer;
    padding:10px 15px;
    top:50%;
    transform: translate(0, -50%);
}

.sign_up_button_container {
    position:relative;
    display:inline-block;
    background-color: transparent;
    height:100%;
    vertical-align: middle;
}

.sign_up_button {
    position:relative;
    background-color: var(--primary-color);
    color:white;
    font-weight:500;
    padding:0px 20px;
    height:35px;
    margin:0px 30px 0px 0px;
    font-size:inherit;
    top:50%;
    transform: translate(0, -50%);
}

.sign_up_button_create {
    position:relative;
    background-color: white;
    color:var(--primary-color);
    font-weight:500;
    padding:0px 20px;
    height:35px;
    margin:0px 30px 0px 0px;
    font-size:inherit;
    top:50%;
    transform: translate(0, -50%);
}

.sign_up_button:hover {
    /* transform: rotate(360deg);
    transition: transform 0.2s; */
    background-color: var(--primary-color-hover)
}

.sign_up_button_icon {
    font-size:18px;
    line-height:0px;
    top:4px;
    position:relative;
    left:-7px;
}

.log_in_button_icon {
    font-size:18px;
    line-height:0px;
    top:4px;
    position:relative;
    left:-7px;
}