.mobile_filters_container {
    left:50%;
    transform: translateX(-50%);
    width:calc(100% - 2em);
    height:50vh;
    position:relative;
    border-radius: 15px;
    background-color: white;
    bottom:1em;
    overflow:scroll;
}

.mobile_filters_header_container {
    border-bottom: 1px solid #e6e6e6;
    height:50px;
    /* padding:0px 20px; */
    margin:0px 20px; 
}

.mobile_filters_title {
    display:inline-block;
    padding:0px;
    line-height:50px;
    font-size:16px;
    font-weight:500;
}

.mobile_filters_close_container {
    float:right;
    font-size:16px;
    line-height:50px;
    position:relative;
    color: var(--primary-color);
    font-weight:500;
}

.mobile_filters_parent_container {
    width:100%;
    height:calc(50vh - 50px);
    overflow:scroll;
}

.mobile_filters_parent_item {
    position:relative;
    display:block;
    width:100%;
    background-color: white;
    height:42px;
    line-height:42px;
    font-size:16px;
    padding:0px 20px;
    box-sizing: border-box;
    border:0px transparent;
    font-weight:400;
}

.mobile_filter_expand_more_container {
    position:relative;
    float:right;
}

.mobile_filter_expand_more {
    display:inline-block;
    position:relative;
    top:5px;
    transition: all 0.3s ease;
}


.mobile_filters_child_background_container {
    background-color: rgb(247, 247, 247);

}

.mobile_filters_child_item_container_show {
    display:block;
    transition: all 0.3s ease;
}

.mobile_filters_child_item_container_hide {
    display:none;
    transition: all 0.3s ease;
}

.mobile_filters_child_container {

    height:0px;
    overflow:hidden;
    transition: all 0.4s ease;
    /* border-bottom: 1px solid rgb(240,240,240); */
    margin:0px 20px;
}

.mobile_filters_child_padding {
    position:relative;
    display:block;
    padding:10px 0px;
    column-count:2;
    overflow:hidden;
}

.mobile_filters_child_item {
    display:block;
    transition: all 0.3s ease;
    height:35px;
    line-height:35px;
}

.mobile_filters_child_item_button {
    background-color: transparent;
    padding:0px 10px;
    color:black;
    font-size:16px;
    padding:0px;
}