.create_delete_recipe_panel_container {
    position:fixed;
    top:0px;
    left:0px;
    width:100vw;
    height:100vh;
    background-color: rgb(0,0,0,0.5);
    z-index:10;
}

.create_delete_recipe_panel {
    width:300px;
    padding:40px;
    background-color: white;
    border-radius:20px;
    position:relative;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%)
}

.create_delete_recipe_panel_text_header {
    font-weight:500;
}

@media only screen and (max-width: 600px) {
    .create_delete_recipe_panel {
        width:calc(100% - 2em   );
        box-sizing: border-box;
        padding: 2em;
    }

    .create_content_ingredients_servings_label {
        line-height:36px;
    }
}