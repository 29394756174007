.create_content_name_container {
    position:relative;
    margin: 0px; 
}

.create_content_name {
    font-size: 50px;
    width:100%;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    position:relative;
    border: 2px solid transparent;
    transition: all 0.4s ease-in-out;
    border-radius: 5px;
    box-sizing: border-box;
    left:-20px;
    width:calc(100% + 40px);
    padding:20px;
    resize:none;
    margin:0px;
}

.create_content_name_edit {
    font-size: 50px;
    width:100%;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    position:relative;
    border: 2px solid transparent;
    transition: all 0.4s ease-in-out;
    border-radius: 5px;
    box-sizing: border-box;
    left:-20px;
    width:calc(100% + 40px);
    padding:20px;
    resize:none;
    margin:0px;
    cursor:not-allowed;
    
}

.create_content_name:hover {
    cursor:pointer;
}

.create_content_name:focus {
    outline: none;
    /* border: 2px solid var(--primary-color); */
    cursor:text;
    background-color: rgb(240,240,240);
    padding:20px;
    margin-bottom:20px;
}

@media only screen and (max-width: 768px) {

    .create_content_name_container {
        position:relative;
        margin: 0px;   
        touch-action: manipulation;
    }

    .create_content_name {
        font-size: 36px; 
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        position:relative;
        border: none;
        transition: all 0.4s ease-in-out;
        border-radius: 0px;
        box-sizing: border-box;
        left:0px;
        width:100%;
        padding:0px;
        resize:none;
        margin:0px;
        line-height:42px;
        margin-bottom:15px; 
    } 
    
    .create_content_name:focus {
        outline: none;
        /* border: 2px solid var(--primary-color); */
        cursor:text;
        background-color: transparent;
        padding:0px; 
        margin-bottom:15px;
        box-sizing: border-box; 
    }
}
