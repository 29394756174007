.language_options_container {
    position:fixed;
    background-color: rgb(0,0,0, 0.5);   
    width: 100vw;
    height: 100vh;
    top:0px;
    left:0px;
    z-index: 1000
}

.language_options_selection_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color:rgb(240, 240, 240);
    width: 250px;
    border-radius: 10px;
    padding:10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    
}

.language_options_title {
    font-weight:500;
}

.language_options_item {
    cursor: pointer;
    padding: 10px 0px;
    background-color: rgb(255, 255, 255);
    margin: 3px;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    font-weight:500;
    /* padding-left:15px; */
    box-sizing: border-box;
}

.language_options_item:hover {
    background-color: var(--primary-color);
    color:white;
    
}

.language_optioons_flag_icon {
    font-size:28px;
    position:relative;
    margin-left: 10px;
    top:5px;
    line-height: 0px;
}

@media only screen and (max-width: 768px) {
    .language_options_selection_container {
        width: calc(100% - 3em);
    }
    .language_options_item {

        padding: 15px 0px;

    }
}