.button_cancel_container {
    position:relative;
    margin:0px;
    padding:0px;
    display:inline-block;
}

.button_cancel_class {
    width:100%;
    padding: 10px 20px;
    border-radius: 5px;
    border: 2px solid var(--cancel-color);
    background-color: var(--cancel-color);
    color: white;
    font-weight:500;
    white-space:nowrap;
}

.button_cancel_class:hover {
    border: 2px solid var(--cancel-color-hover);
    background-color: var(--cancel-color-hover);
}

.button_cancel_class_grey {
    width:100%;
    padding: 10px 20px;
    border-radius: 5px;
    border: 2px solid rgb(220, 220, 220);
    background-color: rgb(220, 220, 220);
    color: rgb(140, 140, 140);
    font-weight:500;
}

.button_cancel_class_grey:hover {
    border: 2px solid var(--cancel-color-hover);
    background-color: var(--cancel-color-hover);
    color: white;
}

.button_cancel_icon {
    display:inline-block;
    /* height:100%; */
    line-height:0px;
    position:relative;
    left:-8px;
    top:-2px;
    vertical-align: middle;
    font-size:22px;
}

.button_cancel_disabled {
    width:100%;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: rgb(210, 210, 210);
    color:rgb(135, 135, 135);
    border: 2px solid rgb(210, 210, 210);
    cursor: not-allowed;
    font-weight:500;
    white-space:nowrap;
}

@media screen and (max-width: 768px) {
    .button_cancel_class, .button_cancel_disabled, .button_cancel_class_grey{ 
        padding: 12px 20px; 
        font-size:14px; 
    } 

    .button_cancel_class:hover {
        border: 2px solid var(--cancel-color);
        background-color: var(--cancel-color);
    }

    .button_cancel_class:active {
        border: 2px solid var(--cancel-color-hover);
        background-color: var(--cancel-color-hover);
    }
}