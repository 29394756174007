.recipe_info_container {
    /* border-bottom: 1px solid rgb(240, 240, 240); */
    /* padding-bottom: 15px; */
    height: 40px;
    margin-bottom:40px;
    text-align:center;
}

.recipe_info_placeholder {
    /* background-color: pink; */
    position:relative;
    width: 710px;
    height: 40px;
    margin:auto;
}

.recipe_info_button_containers {
    display:inline-block;
}

.recipe_info_action_button_container {
    display:inline-block;   
}

.recipe_info_action_button {
    padding: 10px 20px;
    background-color: transparent;
    color:var(--primary-color);
    font-weight:500;
    border:2px solid var(--primary-color);
    border-radius:5px;
    margin-right:5px;
    cursor:pointer;
    position:relative;
}

.recipe_info_action_button:hover {
    background-color: var(--primary-color);
    color:white;
}

.recipe_info_my_collection_button {
    color:white;
    background-color: var(--primary-color);
}

.recipe_info_my_collection_button:hover {
    border: 2px solid var(--primary-color-hover);
    background-color: var(--primary-color-hover);
}

.recipe_info_icon {
    line-height:0px;
    position:relative;
    top:3px;
    left:-7px;
    font-size:20px;
    font-variation-settings:
    'FILL' 0,
    'wght' 500,
    'GRAD' 0,
    'opsz' 48;
    font-size:18px;
}

.recipe_info_report_button_content {
    display:none;
    position:absolute;
    /* margin-top:5px; */
    border-radius:7px;
    background-color: rgb(240, 240, 240);
    padding:5px 0px;
}

.recipe_info_report_button_content_name {
    font-weight:500;
    padding:10px 20px;
    line-height:0px;
    margin:10px 0px;
    font-size:inherit;
}

.recipe_info_report_item {
    width:100%;
    padding:10px 20px;
    display:block;
    border-radius: 0px;
    background-color: transparent;
    text-align:left;
}

.recipe_info_report_item:hover {
    background-color:rgb(250, 250, 250);
}

.report_recipe_input_container {
    position:fixed;
    top:0px;
    left:0px;
    height:100vh;
    width:100vw;
    background-color: rgba(0, 0, 0, 0.5);
    z-index:10000
}

.report_recipe_title {
    margin:0px 0px 20px;
    padding-right:30px;
}

.report_recipe_user_input {
    position:relative;
    width:500px;
    border-radius: 10px;
    background-color: white;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    padding:40px;
    white-space: normal;
    text-align:left;
}

.report_recipe_section_item {
    display:inline-block;
    padding:10px 20px;
    background-color: rgb(240, 240, 240);
    border-radius: 5px;
    margin-right:5px;
    margin-bottom:5px;
    cursor: pointer;
    font-size:14px;
    font-weight:500;
    user-select: none;
}

.report_recipe_section_item_selected {
    display:inline-block;
    padding:10px 20px;
    background-color: var(--primary-color);
    color:white;
    border-radius: 5px;
    margin-right:5px;
    margin-bottom:5px;
    cursor: pointer;
    font-size:14px;
    font-weight:500;
    user-select: none;
}

.report_recipe_textarea {
    font-family: 'Roboto', sans-serif;
    width:100%;
    height:100px;
    padding:10px;
    box-sizing: border-box;
    font-size:16px;
    font-weight:300;
}

.report_recipe_submit_button {
    background-color: rgb(240, 240, 240);
    color:rgb(173, 173, 173);
    border-radius: 5px;
    padding:10px 20px;
    font-size:14px;
    cursor: pointer;
    margin-top:10px;
    font-weight:500;
}

.report_recipe_submit_button_active {
    background-color: var(--primary-color);
    color:white;
    border-radius: 5px;
    padding:10px 20px;
    font-size:14px;
    cursor: pointer;
    margin-top:10px;
    font-weight:500;
}

.report_recipe_close {
    position:absolute;
    right:40px;
    height:30px;
    width:30px;
    padding:0px;
    margin:0px;
}

.report_recipe_close_icon {
    display:block;
    position:relative;
    top:0px;
    left:0px;
    color:grey;
    /* padding:0px; */
    /* margin:0px; */
}

.report_recipe_close:hover {
    background-color: var(--primary-color);
}

.report_recipe_close_icon:hover {
    color:white;
}

.share_options_language_bottom_border {
    display:none;
}


.recipe_info_add_my_collection {
    background-color: var(--primary-color);
    border: 2px solid var(--primary-color);
    padding:10px 20px;
    position:relative;
    border-radius:7px;
    margin-right:5px;
    font-size:14px;
    user-select: none;
    cursor: pointer;
}


.recipe_info_add_my_collection_button {
    background-color: transparent;
    color:white;
    font-weight:500;
}

.recipe_info_create_variation {
    background-color: white;
    border: 2px solid var(--primary-color);
    padding:10px 20px;
    position:relative;
    border-radius:7px;
    margin-right:5px;
    font-size:14px;
    user-select: none;
    cursor: pointer;
}

.recipe_info_create_variation_button {
    background-color: transparent;
    color:var(--primary-color);
    font-weight:500;
}

.recipe_info_report_panel {
    text-align: left;
}

.recipe_info_report_body {
    white-space: normal;
}

@media screen and (max-width: 768px) {

    .recipe_info_placeholder {
        position:relative;
        width: calc(100vw - 2em);
        height: 40px;
    }

    .recipe_info_container {
        /* background-color: rgb(185, 26, 26); */
        padding:15px 1em;
        margin:0px;
        height:auto;
        white-space: nowrap;
        width:100%;
        overflow-x: scroll;
        overflow-y: hidden;
        box-sizing: border-box;
    }

    .recipe_info_ratings{
        font-size:14px;
        padding:10px 15px;
        background-color: white;
    }
    .recipe_info_views {
        background-color: white;
        /* color:rgb(90, 90, 90); */
        border-radius:7px;
        padding:10px 20px;
        position:relative;
        margin-right:5px;
        user-select: none;
        font-size:14px;
        padding:10px 15px;
    }
    .recipe_info_cooking_time {
        font-size:14px;
        padding:10px 15px;
        background-color: white;
        display:none;
    }
    .recipe_info_favourite {
        font-size:14px;
        padding:10px 15px;
        background-color: white;
        display:none;
    }
    .recipe_info_share_recipe {
        font-size:14px;
        padding:10px 15px;
        background-color: var(--primary-color);
        color:white;
        -webkit-tap-highlight-color: transparent;
    }

    .recipe_info_report_button_container {
        display:inline-block;
        font-size:14px;
        padding:10px 15px;
        margin:0px;
        background-color: white;
        border-radius:7px;
        margin-right:1em;
    }
    
    .recipe_info_report_button {
        position:relative;
        top:0px;
        left:0px;
        background-color: rgb(255, 255, 255);
        /* color:rgb(90, 90, 90); */
        padding:0px;
        margin:0px;
        font-size:14px;
        border-radius:7px;
        user-select: none;
        cursor: pointer;
        color:black;
        font-weight:400;
    }

    .recipe_info_report_button_content {
        display:block;
        position:fixed;
        bottom:1em;
        /* margin-top:5px; */
        border-radius:15px;
        background-color: white;
        padding:5px 1em;
        width:calc(100% - 2em);
        left:0px;
        box-sizing: border-box;
        z-index:10000;
        margin:0px 1em;
        transition: all 0.4s ease;
    }
    
    .recipe_info_report_button_content_name {
        font-weight:500;
        padding:0px;
        line-height:42px;
        margin:0px;
        font-size:16px;
        height:42px;
        border-bottom: solid 1px rgb(240, 240, 240);
    }

    .recipe_info_report_item {
        width:100%;
        padding:0px;
        display:inline-block;
        border-radius: 0px;
        background-color: transparent;
        text-align:left;
        height:42px;
        font-size:16px;
        color:black;
    }

    .recipe_info_report_item:hover {
        background-color:transparent;
    }
    
    .report_recipe_input_container {
        position:fixed;
        /* width:80vw; */
        z-index:10000
    }

    .report_recipe_user_input {
        position:relative;
        width:calc(100% - 2em);
        border-radius: 10px;
        background-color: white;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        padding:1.5em;
        white-space: normal;
        box-sizing: border-box
    }

    .report_recipe_close {
        background-color: transparent;
        right:1.5em;
    }

    .share_options_language_bottom_border {
        display:block;
        height:5px;
        background-color: rgb(240, 240, 240);
        width:100%;
    }


}
