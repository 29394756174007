.recipe_filter_container {
    position: relative;
    background-color: rgb(243, 243, 243);
    /* width: 100%; */
    border-radius: 10px;
    margin-bottom: 30px;
    padding:25px 25px 15px 25px;
    box-sizing: border-box;
    font-size:14px;
    user-select:none;
    box-shadow: 0 0 15px rgb(202, 202, 202);
    transition: all 0.4s ease;
}

.recipe_filter_top_row {
    /* background-color: green; */
    height:40px;
    width:100%;
    display:flex;
    justify-content:space-between;
    margin-bottom:25px;
}

.recipe_filter_second_row {
    position:relative;
    /* background-color: green; */
    height:40px;
    margin:0px;
}

.recipe_filter_third_row {
    background-color: rgb(255, 255, 255);
    /* height:50px; */
    padding:10px 10px 0px 10px;
    margin:15px 0px 0px 0px;
    border-radius: 7px;
}

.recipe_filter_search_ui {
    /* background-color: blue; */
    position:relative;
    width:100%;
    min-width:300px;
    margin-right:10px;
}

.recipe_filter_favourite {
    /* background-color: rgb(190, 47, 47); */
    position:relative;
    display: inline-block;
    /* margin-right:10px; */
    padding:0px;
    line-height:40px;
}

.recipe_filter_favourite_button {
    background-color: white;
    color:grey;
    height:100%;
    width:100%;
    border-style: none;
    margin:0px;
    padding:0px 20px;
    border-radius: 7px;
    font-size:inherit;
    cursor:pointer;
    white-space: nowrap;
}

.recipe_filter_favourite_button:hover {
    background-color: gainsboro;
}

.recipe_filter_favourite_button_active {
    background-color:var(--primary-color);
    color:rgb(255, 255, 255);
    height:100%;
    width:100%;
    border-style: none;
    margin:0px;
    padding:0px 20px;
    border-radius: 7px;
    font-size:inherit;
    cursor:pointer;
    white-space: nowrap;
}

.recipe_filter_sort_by {
    position:relative;
    display: inline-block;
    /* background-color: rgb(204, 54, 167); */
    height:40px;
    margin-right:10px;
    padding:0px;
    line-height:40px;
    white-space: nowrap;
    z-index: 100;
}

.recipe_filter_results {
    position:relative;
    display: inline-block;
    height:40px;
    margin-right:10px;
    line-height:40px;
    white-space: nowrap;
    z-index: 100;
}

.recipe_filter_columns {
    position:relative;
    display: inline-block;
    height:40px;
    line-height:40px;
    white-space: nowrap;
    z-index: 100;
}

.recipe_filter_clear_all {
    position:relative;
    display: inline-block;
    /* background-color: red; */
    line-height: 0px;
    height:40px;
    padding:0px 20px;
    cursor:pointer;
    line-height:10px;
}

.recipe_filter_clear_all:hover {
    background-color: red;
    color:white;
    border-radius: 20px;
}

.break_line_filters {
    border: 0.5px solid rgb(255, 255, 255);
    padding:0px;
    margin:0px;
}


.filters_container {
    position:relative;
    /* background-color: rgb(250, 250, 250); */
    width:100%;
    height:100%;
    border-radius: 10px;
}

.filters_list {
    /* background-color: blue; */
    position:relative;
    width:100%;
    height:40px;
    padding:0px;
    top:0px;
    display:flex;
    justify-content: space-between;
}

.filters_item {
    display:inline-block;
    padding:0px 20px;
    border-radius: 7px;
    cursor:pointer;
    line-height:40px;
    color:rgb(117, 117, 117);
    font-weight:400;
}

.filters_item:hover {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    
}

.expand_more {
    /* background-color: green; */
    position:relative;
    line-height:0px;
    top:8px;
    left:-1px;
    width:13px;
    font-variation-settings: 'FILL' 0, 'wght' 300, 'GRAD' 0, 'opsz' 48;
    
}

.active_filters_container {
    /* background-color: rgb(190, 38, 38); */
    /* margin:1px; */
    margin:0px 10px;
}

.active_filters_type_container {
    position:relative;
    display:inline-block;
    height:0px;
    margin:0px;
    padding:0px;
    line-height: 0px;

}

.filters_clear_all {
    background-color: transparent;
    color:rgb(143, 143, 143);
    font-family: inherit;
    border-style:none;
    height:100%;
    font-size:inherit;
    border-radius: 7px;
    padding:8px 20px;
    float:right;
    cursor: pointer; 
}

.filters_clear_all:hover {
    background-color: rgb(235, 235, 235);
    color:black;
}

.inline-icon {
    position:relative;
    vertical-align: top;
    font-size: 18px;
    top:-1.5px;
    margin-left:2px;
 }

.loading_container {
    position:relative;
}

.loader_line {
    position: absolute;
    top:-1px;
    height: 2px;
    width: 100%;
    overflow: hidden;
    border-radius: 5px;
    transition: all 0.2s ease;
 }
 
 .loader_line .bar {
    position: relative;
    height: 2px;
    width: 100%;
    background-color: var(--primary-color);
    animation-name: loader-animation;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    transition: all 0.5s ease;
 }


@keyframes loader-animation {
    0% {
       left: -100%;
    }
    49% {
       left: 100%;
    }
    50% {
       left: 100%;
    }
    100% {
       left: -100%;
    }
 }

 @media only screen and (max-width: 768px) {
    .recipe_filter_third_row {
        background-color: transparent;
        /* height:50px; */
        padding:0px calc(1em - 5px) 10px 1em;
        margin:0px 0px 0px 0px;
        border-radius: 0px;
        white-space: nowrap;
        overflow:scroll;
    }

    .filters_clear_all {
        display:none;
    }
}