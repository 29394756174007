.demo_video_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* You can adjust the height to your needs */
    background-color: rgb(226, 226, 226)
}

.demo_video_content_container {
    height: 80%; /* Adjust the width as needed */
    
}

.demo_video_content {
    height:100%;
    /* width: 35%; */
    box-shadow: (0 10px 10px rgba(0,0,0,0.5));
    border-radius: 15px;
    /* max-width: 960px; Optionally, set a max width */
}

@media screen and (max-width: 768px) {
    .demo_video_content_container {
        height: auto; /* Adjust the width as needed */
        
    }
    .demo_video_content {
        width: 100%;
        border-radius: 0px;
    }
}