.filter_item {
    /* background-color: red; */
    color:rgb(5, 5, 5);
    padding:8px 10px;
    text-decoration: none;
    list-style-type: none;
    border-radius: 5px;
    line-height:normal;
    margin:0px;
}

.filter_item:hover {
    background-color: green;
    color:white;
}