.studio_parent_container {
    padding-top:50px;
    position:relative;
    min-height:100vh;
    box-sizing: border-box;

}

.studio_background_container {
    position:absolute;
    width:100%;
    height:300px;
    overflow:hidden;
}

.studio_background_image {

    /* height:100%; */
    width:100%;
    object-fit: contain;
}

.studio_background {
    position:absolute;
    width:100%;
    height:100%;
    /* z-index:-1; */
    background-image: url('https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/shopping_list.jpg');
    background-repeat:repeat;
    opacity: 0.2;
}

.studio_title_container {
    position:relative;
    padding: 20px 0px;
    width:960px;
    margin:0 auto;
}

.studio_page_header_container {
    position:relative;
}

.studio_page_header_title {
    display:block;
    text-align: center;
}

.studio_unpublished_recipes_button {
    padding:10px 20px;
    border-radius: 5px;
    background-color:white;
    border: 2px solid var(--primary-color);
    color:var(--primary-color);
    font-weight:500;
    top:50%;
    /* transform:translateY(-50%); */
    position:relative;
}

.studio_unpublished_recipes_button:hover {
    background-color:var(--primary-color);
    color:white;
}

.studio_create_recipe_button {
    padding:10px 20px;
    border-radius: 5px;
    background-color: var(--primary-color);
    border: 2px solid var(--primary-color);
    color: white;
    font-weight:500;
    top:50%;
    /* transform:translateY(-50%); */
    position:relative;
    margin-left:5px;
}

.studio_create_recipe_button:hover {
    border : 2px solid var(--primary-color-hover);
    background-color:var(--primary-color-hover);
}

.studio_page_header_my_wip {
    display:inline-block;
    position:absolute;
    right:0px;
    top:50%;
    transform:translateY(-50%);
}

.studio_title {
    font-size: 52px;
    margin-bottom:10px;
}

.studio_subtitle {
    font-size: 20px;
    font-weight:300;
    margin:0px 0px 10px;
    text-align: center;
}

.studio_container {
    position:relative;
    width:100%;
    /* background-color: blue; */
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 20px;
    grid-column-gap: 20px;
}
.grid-container > :last-child {
    grid-column-gap: 0; /* Removes the grid gap for the last column */
  }

.studio_recipe_tile_container {
    height:190px;
}

.studio_create_recipe_container {
    margin:20px 0px;
}

.studio_create_no_recipes_container {
    text-align: center;
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
    padding:40px 100px;
    width:700px;
    margin:auto;
    border: 2px solid var(--primary-color);
    margin-top:50px;
    box-sizing: border-box
}  

@media only screen and (max-width: 768px) {
    .studio_title_container {
        width:calc(100% - 20px);
        padding: 0px;
        box-sizing: border-box;
        position:relative; 
        margin:0 auto;
    }

    .studio_title {
        font-size: 40px;
    }

    .studio_subtitle {
        font-size: 18px;
    }

    .my_collection_no_recipes_title {
        font-size:36px;
    }   

    .studio_container {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 10px;
        row-gap: 10px;
    }

    .studio_create_no_recipes_container {
        width:100%;
        padding:40px 20px 20px;
        border: 2px solid transparent;
        background-color: transparent;
    }

    .studio_recipe_tile_container {
        width: calc((100vw - 2em)/2);
        height: calc((100vw - 2em)/2);
    }

    .studio_create_recipe_container {
        display:grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 5px;
        white-space: nowrap;
    }
}