

.create_instructions_title {
    font-size: 28px;
    font-weight: 500;
    margin:0px;
    padding: 0px;
}

.create_instructions_content_instructions_container {
    margin:20px 0px 0px;
}

.create_instructions_add_button {
    width: 100%;
    color:black;
    font-weight:500;
    background-color: rgb(240, 240, 240);
    height:50px;
    /* margin-top:20px; */
}

.create_instructions_add_button:hover {
    width: 100%;
    color:white;
    font-weight:500;
    background-color: var(--primary-color);
    height:50px;
}

.create_instructions_new_step_container {
    height:0px;
    overflow:hidden;
    transition: all 0.4s ease-in-out;
    /* border: 2px solid var(--primary-color); */
    border-radius:5px;
    box-sizing: content-box;
    position:relative;
}

.create_instructions_textarea {
    width: calc(100% - 100px);
    /* padding:20px; */
    /* left:-20px; */
    position:relative;
    resize:none;
    /* border-radius:5px; */
    font-family:'Roboto', sans-serif;
    border:none;
    font-size:16px;
    background-color: transparent;
}

.create_instructions_textarea {
    outline:none;
    /* border: 2px solid var(--primary-color); */
}

.create_instructions_new_step_submit {
    color:white;
    font-weight:500;
    background-color: var(--primary-color);
    padding:10px 20px;
    right:20px;
    position:absolute;
}

.test {
    width:50px;
    height:50px;
    background-color: red;
}

.create_content_instructions_title_container {
    display:inline-block;
}

.create_content_instructions_servings_container {
    float:right;
}

.create_content_instructions_servings_label {
    display:inline-block;
    font-weight:500;
    margin:0px;
}

.create_content_instructions_servings_input {
    display:inline-block;
    height:30px;
    width:50px;
    margin-left:10px;
    text-align:center;
    border:none;
    background-color: rgb(240, 240, 240);
    border-radius:5px;
    font-size:18px;
    font-weight:500;
    border:2px solid transparent;
}

.create_content_instructions_servings_input:focus {
    outline:none;
}

@media only screen and (max-width: 768px) {

    .create_content_instructions_title_container {
        display:block;
    }

    .create_instructions_textarea {
        width: calc(100% - 50px);
    } 

    .create_content_instructions_servings_container {
        float:none;
        width:100%;
        margin-top:20px;
    }

    .create_content_instructions_servings_input {
        
        float:right;
    }

    .create_content_instructions_servings_label {
        line-height:36px;
    }
    
}