.collection_parent_container {
    padding-top:50px;
    position:relative;
    min-height:calc(100vh - 50px); 
}

.collection_page_header_handle_container {
    padding:5px 20px;
    color:var(--primary-color);
    border-radius: 20px;
    background:rgb(238, 255, 235);
    font-weight:500;
    min-width:100px;
    width:auto;
    display:inline-block;
    position:relative;
    font-size:14px;
    border:2px solid var(--primary-color);
}

.collection_title_image_container {
    position:absolute;
    width:100%;
    height:420px;
    overflow:hidden;
    top:0px;
}

.collection_title_image {
    width:100%;
    object-fit: cover;
}

.collection_background {
    position:absolute;
    width:100%;
    height:calc(100% + 50px);
    /* z-index:-1; */
    background-image: url('https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/shopping_list.jpg');
    background-repeat:repeat;
    opacity: 0.2;
    top:-50px;
}

.collection_title_container {
    position:relative;
    padding: 20px 0px;
    width:960px;
    margin:0 auto;
}

.collection_page_header_container {
    position:relative;
    text-align:center;
    margin-top:20px;
}

.collection_page_header_title {
    display:inline-block;
}

.collection_unpublished_recipes_button {
    padding:10px 20px;
    border-radius: 5px;
    background-color:white;
    border: 2px solid var(--primary-color);
    color:var(--primary-color);
    font-weight:500;
    top:50%;
    /* transform:translateY(-50%); */
    position:relative;
}

.collection_unpublished_recipes_button:hover {
    background-color:var(--primary-color);
    color:white;
}

.collection_create_recipe_button {
    padding:10px 20px;
    border-radius: 5px;
    background-color: var(--primary-color);
    border: 2px solid var(--primary-color);
    color: white;
    font-weight:500;
    top:50%;
    /* transform:translateY(-50%); */
    position:relative;
    margin-left:5px;
}

.collection_create_recipe_button:hover {
    border : 2px solid var(--primary-color-hover);
    background-color:var(--primary-color-hover);
}

.collection_page_header_my_wip {
    display:inline-block;
    position:relative;
    margin:0px 0px 25px;
    width:100%;
    text-align: center;
    /* right:0px; */
    /* top:50%; */
    /* transform:translateY(-50%); */
}

.collection_title {
    font-size: 52px;
    margin:15px 0px 10px;
}

.collection_statistics {
    display:block;
    margin: 0px 0px 30px;
    cursor: help;
    font-weight:500;
}

.collection_subtitle {
    font-size: 18px;
    font-weight:500;
    margin:30px 0px 10px;
}

.collection_container {
    position:relative;
    width:100%;
    /* background-color: blue; */
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 20px;
    grid-column-gap: 20px;
}
.grid-container > :last-child {
    grid-column-gap: 0; /* Removes the grid gap for the last column */
  }

.collection_recipe_tile_container {
    height:190px;
}

.collection_sort_container {
    position:relative;
    display:inline-block;
}

.collection_sign_up_container {
    position:absolute;
    height:100vh;
    top:0px;
    left:0px;
    width:100vw;
}

.collection_sign_up_title_container {
    display:block;
    width:600px;
    position:relative;
    top:50%;
    transform:translateY(-50%);
    margin:0 auto;
    text-align: center;
}

.collection_sign_up_title {
    font-size:42px;
    text-align: center;
    margin:0px 0px 20px;
}

.collection_no_recipes_container {
    text-align: center;
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
    padding:80px;
    width:700px;
    margin:auto;
    border: 2px solid var(--primary-color);
}   

.collection_handle_not_exist_container {
    text-align: center;
    background-color: rgb(255, 255, 255);
    border-radius: 20px;
    padding:40px;
    width:700px;
    margin:auto;
    margin-top:100px;
    /* top:50%; */
    transform:translateY(-50%);
    /* border: 2px solid var(--primary-color); */
}   

@media only screen and (max-width: 768px) {

    .collection_page_header_container {
        margin:0px;
    }

    .collection_title_container {
        position:relative;
        padding: 20px 10px;
        width:calc(100% - 20px);
        margin:0 auto;
    }
    

    .collection_title_image_container {
        position:absolute;
        width:100%;
        height:600px;
        overflow:hidden;
        top:0px;
    }

    .collection_title_image { 
        height:350px; 
    }

    .collection_title { 
        font-size: 36px; 
    }

    .collection_container {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 10px;
        row-gap: 10px;
    }

    .collection_page_header_my_wip {
        display:grid;
        grid-template-columns: repeat(1, 1fr);
        column-gap: 5px;
        position:relative;
        margin:0px 0px 25px;
        width:100%;
        text-align: center; 
    }

    .collection_sign_up_container {
        width:100vw;
    }

    .collection_sign_up_title_container {
        display:block;
        width:calc(100% - 20px);
        position:relative;
        top:50%;
        transform:translateY(-50%);
        margin:0 auto;
        text-align: center;
    }
    

    .collection_recipe_tile_container {
        width: calc((100vw - 2em)/2);
        height: calc((100vw - 2em)/2);
    }

    .collection_sign_up_button_containers {
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 5px;
    }

    .collection_handle_not_exist_container {
        text-align: center;
        background-color: rgb(255, 255, 255);
        border-radius: 20px;
        padding:40px;
        width:100%;
        margin:auto;
        margin-top:100px;  
        box-sizing: border-box;
    }  
}