
.create_content_image {
    width:100%;
    height:100%;
    position:relative;
}

.create_content_recipe_image {
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    height:100%;
    width: 100%;
    object-fit: cover;
}

.create_content_image_upload_button {
    width:100%;
    height:100%;
    font-weight:400;
}

.create_content_upload_image_container {
    position:relative;
    width:100%;
    height:100%;
    background-color: rgba(66, 66, 66, 0.5);
    z-index:10;
    opacity: 0;
    transition: all 0.4s ease-in-out;
}

.create_content_upload_image_container:hover {
    opacity:1;
}

.upload_image_button_container {
    position:relative;
    display:inline-block;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    text-align:center;
    z-index:10;
}