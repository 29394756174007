.recipe_filter_mobile_container {

}

.recipe_filter_mask{
    display:block;
    position:fixed;
    width:100vw;
    height:100vh;
    background-color: rgb(0, 0, 0);
    top:0px;
    left:0px;
    transition:all 0.5s ease;
}

.recipe_filter_mobile_panel_container {
    position:relative;
    background-color: rgb(240, 240, 240);
    z-index:11;
    width:100%;
    padding:20px 0px 0px;
    box-sizing:border-box;
    user-select: none;
    
}

.recipe_filter_mobile_search_container {
    position:relative;
    width:calc(100% - 2em);
    margin:auto;
    height:50px;
    z-index:11;
}

.recipe_filter_mobile_functions_container {
    position:relative;
    display:flex;
    justify-content: space-between;
    width: calc(100% - 2em);
    margin:auto;
    z-index:2;
    /* height:100px; */
}

.recipe_filter_mobile_functions_item {
    background-color: transparent;
    display:inline-block;
    width:32.5%;
    height:25px;
    line-height:25px;
    text-align: center;
    align-items: center;
    user-select: none;
    border-radius:4px;
    color:rgb(75, 75, 75);
    margin:10px;
    font-size:15px;
    font-weight:500;
}

.recipe_filter_mobile_functions_item_selected {
    background-color: var(--primary-color);
    display:inline-block;
    width:32.5%;
    height:33px;
    line-height:33px;
    text-align: center;
    align-items: center;
    user-select: none;
    border-radius:6px;
    color:rgb(255, 255, 255);
    /* color:var(--primary-color); */
    font-size:15px;
    margin:6px 10px;
    font-weight:500;
}

.recipe_filter_mobile_filters {
    position:fixed;
    left:0px;
    /* height:50vh; */
    width:100%;
    background-color: rgb(240, 240, 240);
    z-index:11;
    transition: all 0.5s ease;
    /* padding:20px; */
    box-sizing: border-box;
    
}


.recipe_sort_by_mobile_sort_by {
    position:fixed;
    left:0px;
    width:calc(100% - 2em);
    background-color: rgb(240, 240, 240);
    z-index:11;
    transition: all 0.5s ease;
    box-sizing: border-box;
    margin:1em;
    border-radius: 15px;
    
}

.recipe_filter_mobile_filters_show {
    bottom:0px;
    background-color: transparent;
}

.recipe_filter_mobile_filters_hide {
    bottom:calc(-50vh - 1em);
}

.recipe_filter_loading_container {
    position:relative;
    margin-top:10px;
}


.recipe_filter_mobile_break_line_filters {
    border: solid 1px transparent;
    padding:0px;
    margin:0px;
}

.mobile_sort_by_child_item_button {
    margin:0px;
    padding:10px;
    background-color: transparent;
    /* background-color: green; */
    /* width:100%; */
    height:100%;
    text-align: left;
    color:black;
    font-size:inherit;
}

.recipe_sort_by_mobile_filters {
    position:fixed;
    left:0px;
    height:50vh;
    width:100%;
    background-color: rgb(240, 240, 240);
    z-index:10000;
    transition: all 0.5s ease;
    /* padding:20px; */
    box-sizing: border-box;
    
}

.recipe_sort_by_mobile_filters_show {
    bottom:0px;
}

.recipe_sort_by_mobile_filters_hide {
    bottom:-50vh;
}

@media screen and (max-width: 768px) {
    .recipe_filter_loading_container {
        position:relative;
        margin-top:1px;
        top:-1px;
        z-index:11
    }    
}