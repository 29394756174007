.button_secondary_container {
    position:relative;
    margin:0px;
    padding:0px;
    display:inline-block;
}

.button_secondary_class {
    width:100%;
    padding: 10px 20px;
    border-radius: 5px;
    border: 2px solid var(--primary-color);
    background-color: white;
    color: var(--primary-color);
    font-weight:500;
}

.button_secondary_class:hover {
    border: 2px solid var(--primary-color);
    background-color: var(--primary-color);
    color:white;
}

.button_secondary_icon {
    display:inline-block;
    /* height:100%; */
    line-height:0px;
    position:relative;
    left:-8px;
    top:-2px;
    vertical-align: middle;
    font-size:22px;
}

.button_secondary_disabled {
    width:100%;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: rgb(210, 210, 210);
    color:rgb(135, 135, 135);
    border: 2px solid rgb(210, 210, 210);
    cursor: not-allowed;
    font-weight:500;
}

@media screen and (max-width: 768px) {
    .button_secondary_class:hover {
        border: 2px solid var(--primary-color);
        background-color: white;
        color:var(--primary-color);
    }

    .button_secondary_class:active {
        border: 2px solid var(--primary-color);
        background-color: var(--primary-color);
        color:white;
    }
 
    .button_secondary_class, .button_secondary_disabled { 
        padding: 12px 20px; 
        font-size:14px; 
    }
} 
