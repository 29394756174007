.mobile_sort_by_container {
    height:100%;
    border-radius: 15px;
    overflow:hidden;
}

.mobile_sort_by_container {
    width:100%;
    height:100%;
    position:relative;
    background-color: white;
}

.mobile_sort_by_header_container {
    border-bottom: 1px solid #e6e6e6;
    height:50px;

    margin: 0px 20px;
}

.mobile_sort_by_title {
    display:inline-block;
    line-height:50px;
    font-size:16px;
    font-weight:500;
}

.mobile_sort_by_close_container {
    float:right;
    line-height:50px;
    font-weight:500;
    font-size:16px;
    position:relative;
    color: var(--primary-color);
}

.mobile_sort_by_item {
    height: 42px;
    line-height:42px;
    padding:0px 20px;
    display:block;
    font-size:16px;
    box-sizing: border-box;
    background-color: white;
    color: black;
    width:100%;
    text-align: left;
}