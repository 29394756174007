.recipe_page_container {
    padding-top:90px;
    position:relative;
    text-align: center;
    width:100%;
    background-color: rgb(245,245,245);
}

.recipe_page_top_container {
    background-color: transparent;
    position:absolute;
    top:0px;
}

.recipe_container {    
    min-width: 800px;
    width: 900px;
    margin: 0px auto;
    display:inline-block;
    text-align:left;
}

.recipe_info {
    /* background-color: green; */
    width:100%;
}

.recipe_page_content_container {
    background-color: white;
    padding:50px;
    margin-bottom:50px;
}

.recipe_admin_panel_container {
    width:400px;
    /* height:100vh; */
    top:0px;
    background-color: pink;
    position:relative;
    float:right;
    text-align:left;
    z-index:10000;
    overflow: scroll;
}

.show_admin_panel_button {
    position:fixed;
    top:60px;
    right:20px;
    z-index:100000;
    background-color: rgb(238, 238, 238);
    color: rgb(103, 103, 103);
    padding: 10px;
    cursor: pointer;
}



@media screen and (max-width: 768px) {

    .recipe_page_content_container {
        background-color: white;
        padding:0px;
        margin-bottom:0px;
    }

    .recipe_page_container {
        width: 100vw;
        background-color: rgb(230,230,230);
        /* font-size:40px; */
        padding:50px 0px;
    }

    .recipe_container {    
        /* background-color: blue; */
        width:100vw;
        height:500px;
        min-width:0px;
        display:inline-block;
        text-align:left;
        padding:0em;
        box-sizing: border-box;
    }

    .recipe_page_mobile_break {
        height:10px;
        width:100%;
        background-color: rgb(240,240,240);
    }
    
}
