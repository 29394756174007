.introduction_desktop_container {
    position:relative;
    padding-top:50px;
}


.introduction_desktop_header_background {
    position:absolute;
    top:-50px;
    left:0px;
    height:calc(100% + 50px);
    width:100%;
}

.introduction_desktop_header_content_container {
    width:1000px;
    margin:auto;
    position:relative;
    height:calc(100% - 100px);
    overflow:hidden;
    text-align: center;
    padding:50px;
}

.introduction_desktop_header_title {
    margin:0px;
}

.introduction_desktop_header_info {
    font-size:22px;
    line-height:150%;
}


.introduction_desktop_header_image {
    border-radius:10px;
    width:90%;
    margin-top:20px;
    top:-10px;
}

.introduction_desktop_section_container {
    height:700px;
    margin-bottom:10px;
    background-color: rgb(245, 245, 245); 
    position:relative;
}

.introduction_desktop_section_background {
    opacity:1;
}

.introduction_desktop_section_background {
    position:absolute;
    top:0px;
    left:0px;
    height:100%;
    width:100%;
    background-image: url('https://thesiliconkitchenimages.blob.core.windows.net/imagesbackground/shopping_list.jpg');
    background-repeat:repeat;
    opacity: 0.2;
}

.introduction_desktop_section_first {
    background-color: #FBDA61;
    background-image: linear-gradient(45deg, #FBDA61 0%, #FF5ACD 100%);
}

.introduction_desktop_section_content_container {
    width:1000px;
    margin:auto;
    position:relative;
    height: 100%; 
}

.introduction_desktop_section_content {
    position:relative;
    transform: translateY(-50%);
    top:50%;
}

.introduction_desktop_secion_header {
    margin:0px;
    font-size:36px;
}

.introduction_desktop_section_text {
    font-size:18px;
    line-height:150%;
}

.introduction_desktop_section_button {
    margin-top:20px;
}

.introduction_desktop_section_image_container {
    position:relative;
    top:50%;
    transform: translateY(-50%); 
    height:400px;
    box-shadow: 0px 0px 20px 0px rgba(124, 124, 124, 0.75);
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
}

.introduction_desktop_section_left_text_container {
    float:left;
    height:100%;
    width:35%;
    padding-right:25px;
    box-sizing: border-box;
}

.introduction_desktop_section_right_text_container {
    float:right;
    height:100%;
    width:35%;
    padding-left:25px;
    box-sizing: border-box;
}

.introduction_desktop_section_right_image_container {
    float:right;
    height:100%;
    width:65%;
    box-sizing: border-box;
    padding-left:25px; 
}

.introduction_desktop_section_left_image_container {
    float:left;
    height:100%;
    width:65%;
    box-sizing: border-box;
    padding-right:25px; 
}

.introduction_desktop_section_curate_image {
    width:101%;
    left:-0.5%;
}

@media only screen and (max-width:768px) {

    .introduction_desktop_header_content_container {
        width:100%;
        margin:auto;
        position:relative;
        height:100%;
        overflow:hidden;
        text-align: center;
        padding:2em 1em 1em;
        box-sizing: border-box;
    } 

    .introduction_desktop_header_title {
        margin:0px;
        font-size:36px;
    }

    .introduction_desktop_header_info {
        font-size:18px; 
    }

    .introduction_desktop_header_image {
        border-radius:10px;
        width:100%;
        margin-top:0px;
        top:0px;
    }

    .introduction_desktop_section_container {
        height:480px;
        margin-bottom:10px;
        background-color: rgb(245, 245, 245); 
        position:relative; 
        text-align: center;
    }

    .introduction_desktop_section_content_container {
        width:100%;
        padding:1em;
        box-sizing: border-box;
    }

    .introduction_desktop_section_content {
        position:relative;
        transform:none;
        top:0px;
        margin-top:1em;
    }

    .introduction_desktop_section_left_text_container {
        width:100%;
        height:auto;
        padding-right:0px;
    }

    .introduction_desktop_section_right_text_container {
        float:none; 
    
        width:100%;
        height:auto;
        padding-left:0px;
    }

    .introduction_desktop_section_right_image_container {
        width:100%;
        padding-left:0px;
    }

    .introduction_desktop_section_left_image_container {
        width:100%;
        padding-right:0px;
    }
}

