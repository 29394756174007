.ingredients_list_container {
    width:100%;
}

.ingredients_list_title {
    margin:10px 10px 20px 0px;
    user-select: none;
    font-weight:500;
    font-size:18px;
}

.completed_container {
    background-color: rgb(240, 240, 240);
    box-shadow: rgb(185, 185, 185) 0px 0px 20px;
    padding:20px;
    box-sizing: border-box;
    top:0px;
    width:100%;
    border-radius: 10px;
    display:block;
    margin-bottom:20px;
}

.not_completed_container {
    background-color: rgb(240, 240, 240);
    box-shadow: rgb(185, 185, 185) 0px 0px 20px;
    padding:20px;
    box-sizing: border-box;
    top:0px;
    width:100%;
    border-radius: 10px;
    display:block;
}

.removed_title {
    /* background-color: red; */
    margin-top:20px;
}

.ingredients_items_container {
    width:100%;
    min-width:0px;
    margin:auto;
    display:grid;
    column-gap: 10px;
    row-gap: 10px;
    padding:0em;
    box-sizing: border-box;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto; 
    transition: all 2s ease-in-out;
}

@media screen and (max-width: 768px) {
    .ingredients_list_container {
        background-color: transparent;
        padding:0px;
        width:100%;
        height:100%;
        border-radius: 0px;
        box-sizing: border-box;
        display:block;
        box-shadow: none;
    }

    .ingredients_list_title {
        margin:0px 0px 20px;
        user-select: none;
        display:block;
        font-weight:500;
        font-size:18px;
    }
    
    .completed_container {
        top:0px;
        padding:1em;
        box-sizing: border-box;
        width:100%;
        margin-bottom:15px;
        background-color: rgb(240, 240, 240);
        border-radius: 0px;
    }

    .ingredients_items_container {
        
        width:100%;
        min-width:0px;
        margin:auto;
        display:grid;
        column-gap: 10px;
        row-gap: 10px;
        padding:0em;
        box-sizing: border-box;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto; 

    }

    .not_completed_container {
        top:0px;
        padding:1em;
        box-sizing: border-box;
        width:100%;
        margin-bottom:15px;
        background-color: rgb(240, 240, 240);
        border-radius: 0px;
    }
}