.user_info_parent_container {
    position:relative;
    float:right;
    height:100%;
    background-color: transparent;
}

.user_info_container {
    position:relative;
    display:inline-block;
    background-color: transparent;
    height:100%;
    vertical-align: middle;
    margin-right:35px;
}

.user_info_dropdown_container {
    height:100%;
}

.user_info_welcome {
    position:relative;
    background-color: var(--primary-color);
    color:white;
    font-weight:500;
    padding:0px 20px;
    height:35px;
    /* margin:0px 30px 0px 0px; */
    font-size:inherit;
    top:50%;
    transform: translate(0, -50%);
    cursor: pointer;
}

.user_info_welcome_create {
    position:relative;
    background-color: white;
    color:var(--primary-color);
    font-weight:500;
    padding:0px 20px;
    height:35px;
    /* margin:0px 30px 0px 0px; */
    font-size:inherit;
    top:50%;
    transform: translate(0, -50%);
    cursor: pointer;
}


.user_info_welcome:hover {
    background-color: var(--primary-color-hover);
}

.log_out_container {
    display:inline-block;
    background-color: transparent;
    height:100%;
    vertical-align: middle;
}

.user_info_log_out {
    position:relative;
    background-color:transparent;
    height:40px;
    border-style: none;
    margin:0px 10px;
    font-family:inherit;
    font-weight:inherit;
    font-size:inherit;
    color:rgb(124, 124, 124);
    cursor:pointer;
    padding:10px 15px;
    top:50%;
    transform: translate(0, -50%);
}

.user_info_log_out_create {
    position:relative;
    background-color:transparent;
    height:40px;
    border-style: none;
    margin:0px 10px;
    font-family:inherit;
    font-weight:inherit;
    font-size:inherit;
    color:white;
    cursor:pointer;
    padding:10px 15px;
    top:50%;
    transform: translate(0, -50%);
}

.user_info_log_out_create {
    position:relative;
    background-color:transparent;
    height:40px;
    border-style: none;
    margin:0px 10px;
    font-family:inherit;
    font-weight:inherit;
    font-size:inherit;
    color:rgb(255, 255, 255);
    cursor:pointer;
    padding:10px 15px;
    top:50%;
    transform: translate(0, -50%);
}

.user_info_log_out:hover {
    color:var(--primary-color);
}

.user_info_settings {
    margin:0px;
    padding:0px;
}
