.key_features_container {
    position:relative;
    /* height:500px; */
    width:100%;
    background-color:rgb(250, 250, 250);
    padding:20px 0px 50px 0px;
}

.key_features_content_container {
    position:relative;
    margin:auto;
    display:grid;
    grid-template-columns:1fr 1fr 1fr;
    column-gap:10px;
    width:960px;
}

.key_features_content {
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    color:rgb(0, 0, 0);
    font-size:1.5rem;
    font-weight:bold;
    text-align:center;
    padding:0 1rem;
}

.key_features_content_image {
    width:200px;
    height:200px;
}

.key_features_content_title {
    font-size:26px;
    margin:0px 0px 10px 0px;
}

.key_features_content_information {
    font-weight:300;
    font-size:18px;
    line-height:140%;
}

@media screen and (max-width: 768px) {
    .key_features_content_container {
        width:100%;
        padding:0 20px;
        grid-template-columns:1fr;
        column-gap:10px;
        box-sizing: border-box;
    }
}