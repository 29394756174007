.share_options_container {
    position:fixed;
    background-color: transparent;
    width: 100vw;
    height: 100vh;
    top:0px;
    left:0px;
    z-index: 1000;
    user-select:none;
    text-align:left;
}

.share_options_selection_container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color:rgb(255, 255, 255);
    width: 350px;
    border-radius: 10px;
    /* padding:10px; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;
    padding-top:40px;
}

.share_options_title {
    font-weight:500;
    margin-bottom:0px
}

.share_options_languages_container {
    background-color:rgb(240, 240, 240);
    width:100%;
    padding:10px 10px 0px;
    box-sizing: border-box;
    text-align: center;
    /* font-size:14px; */
    font-weight:500;
}

.share_options_item {
    cursor: pointer;
    padding: 10px 0px;
    background-color: rgb(255, 255, 255); 
    margin: 5px 0px;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    font-weight:500;
    
}

.share_options_item:hover {
    background-color: var(--primary-color);
    color:white;
}

.share_copy_link_container {
    width:100%;
    text-align: center;
    border-radius: 7px;   
    height:50px;
}

.share_copy_link_button {
    /* padding: 10px 0px; */
    background-color: transparent;
    width:100%;
    color:white;
    font-weight:500;
    border-radius: 5px;
    height:50px;
    box-sizing: border-box;
    color:var(--primary-color);
    
}   

.share_copy_link_button:hover {
    color: var(--primary-color-hover);
}

.share_options_target_language {
    font-weight:500;
    font-size:2em;
    margin:10px 0px 15px;
    display:inline-block;
}

.share_options_target_flag_icon {
    font-size:2.5em;
    line-height: 0px;
    position:relative;
    top:3px;
    margin-left:10px;
}

.share_options_language_options_title {
    margin:5px 5px 15px;
    padding:0px;

}

.share_option_linked_copied {
    font-weight:500;
    background-color: var(--primary-color);
    color:white;
    padding:15px 20px;
    border-radius: 8px;
    font-size:16px;
    margin:0px;
    text-align: center;
}

.share_option_linked_copied_message {
    font-weight:300;
    margin:10px 0px 0px;
    white-space: normal;
}

.linked_copied_icon {
    position:relative;
    top:6px;
    left:-6px;
    height:0px;
    line-height:0px;
    margin:0px;
    padding:0px;
    font-size:20px;
}

.share_option_url_container {
    padding:0px;
    width:100%;
    box-sizing: border-box;
    margin:0px;
}

.share_option_url_link {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    height:40px;
    margin-bottom:20px;
    border: 2px solid var(--primary-color);
    border-radius: 5px;
    width:100%;
    padding: 5px 10px;
    box-sizing: border-box
}

.share_option_url_link:focus {
    outline:none;
    /* border:none; */
}

.share_options_flag_icon {
    font-size:24px;
    line-height: 0px;
    position:relative;
    top:3px;
    margin-left:3px;
}

@media only screen and (max-width: 768px) {
    .share_options_selection_container {
        width: calc(100vw - 2em);
    }

    .share_options_languages_container {
        max-height:300px;
        overflow-y: scroll;
    }

    .share_options_item:hover {
        /* background-color: white; */
    }

    .share_options_item {
        -webkit-tap-highlight-color: var(--primary-color);
    }
}