.search_container {
    /* background-color: rgb(53, 35, 214); */
    position:relative;
    height:100%;
    width:100%;
    transition: all .4s ease;
}

.search_input {
    background-color: white;
    position:absolute;
    /* background-color: transparent; */
    border-style: none;
    border-radius: 7px;
    height:100%;
    width:100%;
    padding:0px 20px;
    box-sizing: border-box;
    font-size:14px;
    color:grey;
    font-family:inherit;
    font-weight:400;
    border: 2px solid transparent;
}

.search_input:focus {
    border: 2px solid var(--primary-color);
    outline:none;
}

.search_button {
    /* background-color: rgb(255, 255, 255); */
    background-color: transparent;
    position:absolute;
    right:35px;
    color:rgb(92, 92, 92);
    border-radius: 20px;
    top:5px;
    border-style: none;
    font-size:18px;
    transition: all .4s ease;
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
}

.search_symbol {
    /* background-color: red; */
    position:absolute;
    font-size:30px;
    top:0px;
    color:rgb(210, 210, 210);
}

.search_symbol:hover {
    color:var(--primary-color);
}

@media screen and (max-width: 768px) {

    .search_container {
        background-color:white;
        position:relative;
        height:100%;
        width:100%;
        border-radius: 7px;
        border: 3px solid var(--primary-color);
        box-sizing: border-box
    }

    .search_form {
        position:relative;
        border-radius: 7px;
        height:100%;
        width:100%;
        background-color: transparent;
        padding:0px;
        margin:0px;
        overflow:hidden;
        /* display:inline-block; */
        left:50%;
        transform: translateX(-50%);
        transition: all .4s ease-in-out;
    }

    .search_input {
        position:absolute;
        border-radius: 7px;
        background-color: transparent;
        height:100%;
        width:100%;
        display:inline-block;
        padding:0px 45px 0px 20px;
        margin:0px;
        font-size:16px;
        color:var(--primary-color);
        transition:all 0.4s ease;
        border-radius: 0px;
        border: none;
    }

    .search_input:focus {
        border:none;
        outline:none;
        left:0%;
        transform: translateX(0%);
        /* width:100%; */
    }
        
    .search_button {
        width:50px;
        height:50px;
        top:0px;
        background-color: transparent;
        position:relative;
        float:right;
        right:0px;
        border-radius: 0px;
        border-style: none;
        padding:0px;
    }

    .search_symbol {
        position:relative;
        left:3px;
        top:-1px;
        font-size:30px;
        line-height:30px;
        color: var(--primary-color);
        width:50px;
        font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 48;
    }

}
