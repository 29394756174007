.home_hero_introduction_parent_container {
    width: 100%;
    margin: auto;
    position: absolute;
    height: 100%;
    background-color: transparent;
    top: 0px;
    left: 0px;
    text-align: center;
}

.home_hero_introduction_container {
    width:550px;
    position: relative;
    top: 55%;
    /* left: 50%; */
    transform: translateY( -50%);
    /* width:1450px; */
    margin: auto;
    padding: 0px 50px;
    box-sizing: border-box;
    /* background-color: pink; */
}

.home_hero_title {
    margin-top: 15px;
    margin-bottom: 0px;
    font-weight:600;
}

.home_hero_sub_title {
    font-weight: 500;
    margin: 0px;
}

.home_hero_introduction_information {
    font-size: 18px;
    line-height: 150%;
    margin-top: 10px; 
}

.home_hero_button_container {
    margin-top: 15px;
}

.home_hero_introduction_prompt {
    width:100%;
    /* height:200px; */
    border-radius:10px;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(10px);
    padding:10px;
    box-sizing: border-box;
}   

.home_hero_introduction_prompt_text {
    height:110px;
    width:100%;
    font-size:18px;

    margin-bottom:10px;
    
}

.home_hero_introduction_prompt_text_area {
    width:100%;
    height:100%;
    border:transparent;
    outline:none;
    resize:none;
    /* padding:8px; */
    box-sizing: border-box;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.5);
    font-size:24px;
    color:var(--primary-color);
    font-family:inherit;
    font-weight:500;

    padding:10px;
}

.home_hero_introduction_loading_bar {
    margin-top:10px;
}

.home_hero_introduction_error_message {
    color:var(--cancel-color);
    font-size:14px;
    font-weight:500;
    margin: 10px 0px 0px;
    text-align: left;
}   

.home_hero_introduction_tip {
    font-weight:500;
    font-size:14px; 
    color:rgb(100, 100, 100);
    margin-bottom:0px;
}

@media screen and (max-width: 768px) {

    .home_hero_introduction_parent_container {
        padding: 1em 1em 1em 1em;
        box-sizing: border-box;
        white-space: normal;
    }

    .home_hero_introduction_container {
        position: absolute;
        top: auto;
        bottom:10px;
        left: 0px;
        transform: none;
        width: 100%;
        padding: 1.5em;
        box-sizing: border-box;
    }

    .home_hero_title {
        margin-top: 15px;
        font-size: 34px;
        margin-bottom: 10px;
        font-weight:500;
    }

    .home_hero_sub_title {
        font-weight: 500;
        font-size: 14px;
        margin: 0px;
    }

    .home_hero_introduction_information {
        font-size: 18px;
        line-height: 150%;
        margin-top: 0px; 

    }

    .home_hero_mask_temp {
        display: none;
        position: absolute;
        top: 52%;
        height: 100vh;
        width: 200vw;
        left: -50%;
        /* transform: translate(-50%, -50%); */
        background-color: rgb(241, 241, 241, 0.5);
        transform: rotate(0deg);
    }

    .home_hero_button_container {
        display: block;
        /* grid-template-columns: 1fr; */
        /* grid-gap: 5px; */
    }
}