.recipe_ingredients_container {
    margin:0px;
    padding:0px 0px 35px;
    background-color: white;
    /* border-bottom: solid 1px rgb(240, 240, 240); */
}

.recipe_ingredients_info_container {
    margin:15px 0px;
}

.recipe_ingredients_title {
    display:inline-block;
    font-weight:500;
    font-size:28px;
    padding:0px;
    margin:0px;
}

.recipe_ingredients_title_icon {
    font-size:12px;
    vertical-align: top;
}


.recipe_ingredients_action_button_container {
    display:flex;
    vertical-align: middle;
    float:right;
    position:relative;
    top:0px;
    right:0px;
    /* display:grid; */
    /* grid-template-columns: 1fr 1fr 1fr 1fr; */
    /* column-gap:10px;  */
}

.recipe_ingredients_all_ingredients_container {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto; 
    padding:20px 0px;
    row-gap:10px;
    column-gap:10px;
}

.recipe_ingredients_action_button_item {
    display:inline-block;
    width:160px;
    height:45px;
    border-radius: 5px;
    border: 2px solid var(--primary-color);
    position:relative;
    color: var(--primary-color);
    margin-left:5px;
}

.recipe_ingredients_action_button_item_measurements {
    height:45px;
    width:45px;
    border: 2px solid var(--primary-color);
    border-radius: 5px;
    margin-left:5px;
    cursor: pointer;
    color:var(--primary-color);
}

.recipe_ingredients_action_button_item_measurements:hover {
    background-color: var(--primary-color);
    color:white;
}

.recipe_ingredients_action_button_item_measurements_icon {
    width:100%;
    text-align: center;
    display:inline-block;
    position:relative;
    top:50%;
    transform:translateY(-50%); 
} 

.recipe_ingredients_action_button_measurement {
    width:100%;
    height:100%;
    background-color: transparent;
    border-radius: 0px;
    padding:0px;
    display:flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-color);
}

.recipe_ingredients_action_button_measurement_text {
    display:inline-block;
    font-size:16px;
    margin:0px;
    padding:0px;
    font-weight:500;
    /* background-color: blue; */

}

.recipe_ingredients_action_button_measurement:hover {
    background-color: var(--primary-color);
    color:white;    
}

.measurement_settings_item_title {
    margin-bottom:10px;
}

.toggle_container {
    width:100%;

}

.toggle_button_left {
    border-radius: 5px 0px 0px 5px;
    border: 2px solid var(--primary-color);
    padding:15px;
    width:50%;
    background-color: transparent;
    font-weight:500;
    color:var(--primary-color);
}

.toggle_button_right {
    border-radius: 0px 5px 5px 0px;
    border: 2px solid var(--primary-color);
    padding:15px;
    width:50%;
    background-color: transparent;
    font-weight:500;
    color:var(--primary-color);
}

.toggle_button_active {
    background-color: var(--primary-color);
    color:white;
} 

.recipe_ingredients_action_button_servings {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap:10px;
    height:100%;
}

.recipe_ingredients_action_button_servings_info {
    display:inline-block;
    white-space: nowrap;
    position:relative;
    height:100%;
    
}

.recipe_ingredients_action_button_measurement_icon {
    /* background-color: red; */
    display:inline-block;
    align-items: center;
    padding:0px 5px;
}

.recipe_ingredients_action_button_measurement_icon_undo {
    position:absolute;
    right:0px;
    top:0px;
    height:5px;
    font-size:20px;
}   

.recipe_ingredients_action_button_servings_info_text {
    position:relative;
    top:50%;
    transform:translateY(-50%);
    padding:0px;
    margin:0px;
    font-weight:500;
    font-size:14px;
    
}

.recipe_ingredients_serving_info_container {
    /* background-color: rgb(242, 242, 242); */
    position:relative;
    text-align: center;
    border-radius: 5px;
    width:160px;
    display:inline-block;
    padding:12px 0px;
    font-size:14px;
    font-weight:500;
    line-height:16.5px;
    box-shadow: 5px 5px 0px var(--primary-color);
    border: 1px solid var(--primary-color);
    margin-right:15px;
    user-select: none;
}

.serving_adjust_button_container {
    color:var(--primary-color);
    cursor: pointer;
}

.serving_adjust_button_container:hover .serving_adjust_button_minus  {
    color:white;

    background-color: var(--primary-color);
    border-radius: 100px; 
}

.serving_adjust_button_container:hover .serving_adjust_button_add  {
    color:white;

    background-color: var(--primary-color);
    border-radius: 100px; 
    
}

.serving_adjust_button_minus {
    position:absolute;
    background-color: transparent;
    width:25px;
    height:25px;
    left:10px;
    top:50%;
    transform:translateY(-50%);
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48;
    color:inherit;
    
}

.serving_adjust_button_add {
    position:absolute;
    background-color: transparent;
    width:25px;
    height:25px;
    right:10px;
    top:50%;
    transform:translateY(-50%);
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48; 
    color:inherit;
}

.serving_adjust_button_add:hover {
    background-color: var(--primary-color);
    border-radius: 100px; 
}

.serving_adjust_button_icon {
    position:relative;
    font-size:18px;
    left:-3px;
    top:2px;
    
} 

.recipe_ingredients_table {
    columns: 3;
    -webkit-columns: 3;
    -moz-columns: 3;   
    list-style-type:none;
    padding:0px;
}

.recipe_ingredients_item {
    padding: 7px 0px;
}

.recipe_ingredients_quantity {
    font-weight:500;
}

.add_shopping_list_container {
    display:inline-block
}

.add_shopping_list_button {
    position:relative;
    width:100%;
    height:100%;
    margin:0px; 
    font-size:14px;
    cursor: pointer; 
    font-weight:500;
    display:inline-block;
    border-radius: 0px;
    background-color: transparent;
    padding:0px;
    color:var(--primary-color);
}

.add_to_basket_add_icon {
    position:relative;
    font-size:18px;
    top:4px;
    margin:0px;
    padding:0px;
    line-height:0px;
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
}

.add_shopping_list_button:hover {
    background-color: var(--primary-color); 
    color:white;
}

.recipe_ingredients_container_admin {
    margin:0px;
    padding:0px 0px 150px;
    background-color: white;
    /* border-bottom: solid 1px rgb(240, 240, 240); */
}

.recipe_ingredients_admin_update_container {
    height:50px;
}

.recipe_ingredients_parent_container {
    position:relative;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
}

.recipe_ingredients_parent_container:hover {
    box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
}

.recipe_ingredients_completed_overlay {
    display:none;
    position:absolute;
    height:40px;
    width:40px;
    background-color: var(--primary-color);
    top:76px;
    left:50%;
    transform: translate(-50%, -50%);
    border-radius: 100px;
}

.recipe_ingredients_completed_overlay_icon {
    position:relative;
    color:white;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
}

.recipe_ingredients_tip {
    font-size:14px;
    margin:0px;
    color:grey;
    margin-bottom:20px;
}

@media screen and (max-width: 768px) {
    .recipe_ingredients_container {
        padding:35px 1em 0px;
        font-size:1em;
        margin-bottom:15px;
    }

    .recipe_ingredients_table {
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;   
        /* list-style-type:none; */
        /* padding:0px; */
        font-size:18px;
        margin:0px;
    }

    .recipe_ingredients_info_container {
        margin:0px 0px;
    }

    .recipe_ingredients_title {
        display:inline-block;
        font-weight:500;
        font-size:1.5em;
        padding:0px;
        margin:0px;
    }

    .recipe_ingredients_all_ingredients_container {
        display:grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto; 
        padding:20px 0px;
        row-gap:10px;
        column-gap:10px;
    }

    .recipe_ingredients_settings_container {
        display:flex;
        vertical-align: top;
    }

    .recipe_ingredients_action_button_item_measurements {
        display:inline-block;
        width:60px;
        height:60px;
        float:right;
        box-sizing: border-box;
        background-color: var(--primary-color);
        color:white;
    }
 
    .recipe_ingredients_serving_info_parent_container {
        display:inline-block;
        width:calc(100% - 65px);
    }

    .recipe_ingredients_serving_info_container {
        width:100%;
        height:60px;
        font-size:1em;
        box-shadow: 0em 0em 0px;
        background-color: var(--primary-color);
        border: 4px solid var(--primary-color); 
        margin-bottom:10px;
        padding: 0px;
        border-radius: 5px;
        /* display:flex; */
        /* justify-content: center; */
        display:grid;
        grid-template-columns: 1fr 1fr 1fr;
        text-align: center;
        align-items: center;
        box-sizing: border-box;
        margin-top:0px;
        color:white;
    }

    .recipe_ingredients_serving_info_item {
        display:inline-block;
        position:relative;
    }

    .serving_adjust_button_minus {
        position:absolute;
        background-color: transparent;
        width:25px;
        height:25px;
        width:50%;
        top:50%;
        transform:translateY(-50%);
        color:white;
        
        font-variation-settings:
        'FILL' 0,
        'wght' 300,
        'GRAD' 0,
        'opsz' 48
    }
    
    .serving_adjust_button_minus:hover {
        background-color: var(--primary-color);
        color:white;
    }
    
    .serving_adjust_button_icon {
        position:relative;
        font-size:26px;
        /* left:-13px; */
        top:-1px;
    }

    .serving_adjust_button_add {
        position:absolute;
        background-color: transparent;
        width:25px;
        height:25px;
        width:50%;
        top:50%;
        transform:translateY(-50%);
        color:white;
        font-variation-settings:
        'FILL' 0,
        'wght' 300,
        'GRAD' 0,
        'opsz' 48;
    }
    
    .serving_adjust_button_add:hover {
        background-color: var(--primary-color);
        color:white;
    }

    .add_shopping_list_container {
        width:100%;
        font-size:1em;
        box-shadow: 0em 0em 0px;
        border: 4px solid var(--primary-color);
        display:block;
        margin-bottom:0px;
        height:60px;
        border-radius: 5px;
        box-sizing: border-box;
    }
    
    .add_shopping_list_button {
        width:100%;
        position:relative;
        /* margin:0px; */
        /* padding: 15px 30px; */
        background-color:var(--primary-color);
        font-size:1em;
        cursor: pointer;
        box-shadow: 0em 0em 0px;
        display:block;
        color:white;
        border: none;
        height:100%;
        border-radius: 0px;
    }

    .add_shopping_list_container:hover {
        background-color: var(--primary-color);
        box-shadow: 5px 5px 0px white;
        color:white;
    }

    .add_shopping_list_button:hover {
        background-color: none;
        box-shadow: none;
        color:white;
    }


    .recipe_ingredients_completed_overlay {
        top:calc((100vw - 2em - 20px) / 6);
    }

}
