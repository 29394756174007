.about_container {
    position:relative;
    /* top:-50px; */
    height:100vh;
    min-height:800px;
}

.about_us_image {
    position:absolute;
    width:100%;
    min-height:100vh;
    top:0px;
    object-fit: cover;
    
}

.purpose_text_title_container {
    display:block;
}

.purpose_sub_title {
    letter-spacing: 0.2em;
}

.purpose_container {
    /* background-color: pink; */
    position:relative;
    width:700px;
    /* height:100px; */
    top:50%;
    margin:auto;
    transform: translate(0%,-50%);
}

.purpose_container_text_container {
    background-color: rgba(240, 240, 240, 0.8);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    width:700px;
    height:100%;
    position:absolute;
}

.purpose_container_text {
    width:600px;
    padding:50px;
    opacity: 1;
    z-index:10000;
    position:relative;
}
.purpose_title {
    margin:0px;
    line-height: 120%;
    font-size:40px;
    font-weight:500;
}

.purpose_statement {
    position:relative;
    line-height:170%;
    top:10px;
}

.enquries_email {
    text-decoration: none;
}

.enquries_email:hover {
    text-decoration:underline;
}

@media screen and (max-width: 768px) {
    
    .about_container {
        height:100vh;
        min-height:0px;
        background-color: white;
    }

    .about_us_image {
        min-height:0px;
        height:100vh;
        display:none;
    }

    .purpose_container {
        width:100%;
        min-width:0px;
        top:0px;
        transform: translate(0,0);
    }

    .purpose_text_title_container {
        background-color: var(--primary-color);
        color:white;
        display:block;
        padding:70px 1em 1em;
    }

    .purpose_container_text_container {
        background-color: white;
        backdrop-filter: blur(10px);
        width:100%;
        min-width:0px;
        height:100vh;
        min-height:0px;
        border-radius: 0px;
        
        
    }

    .purpose_container_text {
        width:100%;
        min-width:0px;
        padding:0px;
        top:0px;
        box-sizing: border-box;
    }

    .purpose_title {
        font-size:32px;
    }

    .purpose_statement {
        top:0px;
        padding:1em;
        background-color: rgb(255, 255, 255);
        margin:0px;
    }
}
