.logo_social_container {
    position:relative;
    /* background-color: red; */
    left:25px;
    width:100%;
    height:100%;
    white-space:nowrap;
    align-items: center;

}

.logo_container {
    position:relative;
    height:100%;
    /* background-color: pink; */
    display:inline-block;
    top:0px;
    vertical-align:top;
    padding-right:15px;
}

.logo_content {
    position:relative;
    margin:0px;
    color:black;
    font-weight:500;
    cursor:pointer;
    background-color: transparent;
    border-style: none;
    font-family: inherit;
    padding:0px;
    top:50%;
    transform: translate(0, -50%);
    line-height:50px;
}

.logo_content_create {
    position:relative;
    margin:0px;
    color:rgb(255, 255, 255);
    font-weight:500;
    cursor:pointer;
    background-color: transparent;
    border-style: none;
    font-family: inherit;
    padding:0px;
    top:50%;
    transform: translate(0, -50%);
    line-height:50px;
}

.logo_content:hover {
    color:var(--primary-color);
}

.social_media_container {
    position:relative;
    display:inline-block;
    /* background-color: pink; */
    height:30px;
    width:95px;
    top:0px;
    height:100%;
    vertical-align:top;
}

.instagram_link_icon {
    display:inline-block;
    position:relative;
    background-color: transparent;
    top:50%;
    transform: translate(0, -50%);
    left:0px;
    height:23px;
    width:23px;
    padding:0px 10px 0px 0px;
    cursor: pointer;
} 

.instagram_icon_container_create {
    fill:white;
}

.instagram_icon_container:hover svg {
    fill: var(--primary-color);
}

@media screen and (max-width: 768px) {

    .logo_social_container {
        left:0px;
    }

    .logo_container {
        position:relative;
        /* background-color: pink; */
        top:50%;
        transform: translate(0, -50%);
        width:100%;
        padding:0px;
        text-align: left;
        left:0px;
    }
    
    .logo_content {
        margin:0px 0px 0px calc(1.25em);
        color:black;
        font-weight:500;
        cursor:pointer;
        background-color: transparent;
        border-style: none;
        font-family: inherit;
        padding:0px 0px 0px 0px;
        -webkit-tap-highlight-color: transparent;
    }

    .logo_content_create {
        margin:0px 0px 0px calc(1.25em);
        color:rgb(255, 255, 255);
        font-weight:500;
        cursor:pointer;
        background-color: transparent;
        border-style: none;
        font-family: inherit;
        padding:0px 0px 0px 0px;
        -webkit-tap-highlight-color: transparent;
    }

    .logo_content:hover {
        color: black;
    }

    .social_media_container {
        display:none;
    }
}