.coming_soon_container {
    position: relative;
    text-align: center;
}

.coming_soon_message {
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 100px;
    /* background-color: rgb(239, 239, 239); */
    width:80%;
    border-radius: 20px;
    padding:20px;
    font-weight: normal;
    color:white;
}

.coming_soon_image {
    min-height:100%;
    min-width:100%;
}

.coming_soon_logo {
    position:absolute;
    top:40px;
    margin:auto;
    /* background-color: pink; */
    font-size: 30px;
    font-weight:500;
    left:50%;
    transform: translate(-50%, 0%);
}

.testing_notification {
    position:fixed;
    bottom:10px;
    right:10px;
    background-color: rgb(187, 16, 16);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    z-index:100000;
}