.recipe_similar_recipes_container {
    position:relative;
    background-color: rgb(230, 230, 230);
    /* padding:20px 0px; */
    min-width: 960px;
    text-align:left;
}

@media screen and (max-width: 768px) {
    .recipe_similar_recipes_container {
        position:relative;
        top:50px;
        min-width: 0px;
        padding:0px;
        /* height:460px; */
    }
}