.rate_recipe_container {
    padding:30px 0px 0px;
    font-weight:500;
    
}

.rate_recipe_title {
    margin: 0px 0px 10px;
    font-weight:500;
    font-size:16px
}

.star_rating {
    position:relative;
    display: inline-block;
    border:1px solid var(--primary-color);
    padding:20px;
    font-size:40px;
    line-height: 20px;
    height:20px;
    cursor: pointer;
    box-shadow: 5px 5px 0px var(--primary-color);
    border-radius: 5px;
}

.star_single {
    padding:0px 5px;
}

@media screen and (max-width: 768px) {

    .rate_recipe_container {
        position:relative;
        padding:25px 1em 20px;
        font-size:1em;
        width:100%;
        background-color: white;
        box-sizing: border-box;
    }
    
    .rate_recipe_title {
        font-size:1em;
    }
    
    .star_rating {
        position:relative;
        display: flex;
        justify-content:space-around;
        border:4px solid var(--primary-color);
        background-color: rgb(250,250,250);
        /* padding:20px; */
        /* font-size:2em; */
        /* line-height: 20px; */
        align-items: center;
        height:75px;
        cursor: pointer;
        border-radius: 5px;
        /* line-height:1em; */
        box-shadow: 0px 0px 0px transparent;
        width:100%;
        box-sizing: border-box;
        
    }
    
    .star_single {
        padding:0px 2px;
        -webkit-tap-highlight-color: transparent;
    }
    
}