.image_uploader_container {
    position: relative;
    /* background-color: red; */
    /* height: 50px */
    /* width: 200px; */
    text-align: center;
}

.image_uploader_button {
    display: none;
}

label {
    cursor: pointer;
}

#image_uploader_button {
    display: none;
}

.file-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.image_uploading_file_input_container {
    margin-bottom:30px;
}

.file-input-label {
    padding: 10px 20px;
    background-color: white;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
    cursor: pointer;
    border-radius: 4px;
    font-weight:500;
}

.file-input-label:hover {
    background-color: var(--primary-color);
    color: white;
}

.file-input-label-disabled {
    padding: 10px 20px;
    background-color: rgb(210, 210, 210);
    color: rgb(135, 135, 135);
    border: rgb(210, 210, 210);
    cursor: pointer;
    border-radius: 4px;
    font-weight:500;
    cursor:not-allowed;
}

input[type='file'] {
    display: none;
}

.image_uploading_file_name {
    font-weight:500;
}   
.image_uploader_iamge_caption {
    color:white;
}