.nav_container {
    background-color: rgba(243, 243, 243, 0.8);
    width:100vw;
    height:50px;
    position: fixed;
    min-width:960px;
    box-shadow: 0 0 20px grey;
    backdrop-filter: blur(10px);
    z-index:1000;
    font-size:12px;
    font-weight:500;
    transition: all 0.4s ease;
}

.nav_container_create {
    background-color: var(--primary-color);
    width:100vw;
    height:50px;
    position: fixed;
    min-width:960px;
    box-shadow: 0 0 20px grey;
    backdrop-filter: blur(10px);
    z-index:1000;
    font-size:12px;
    font-weight:500;
}

.nav_content {
    /* background-color: blue; */
    color: white;
    position: relative;
    height:50px;
    width:100%;
    margin:auto;
    display:flex;
    justify-content: space-between;
}

.nav_logo_container {
    /* background-color: blue; */
    display:inline-block;
    height:100%;
    position:relative;
    /* left:25px; */
    width:250px;
}

.nav_links_container {
    /* background-color: blue; */
    display:inline-block;
    height:100%;
    /* width:100px; */
}

.nav_account_information_container {
    /* background-color: blue; */
    display:inline-block;
    height:100%;
    position:relative;
    /* right:25px; */
    width:250px;
    overflow: visible;
    white-space: nowrap;
}

.nav_mobile_search_container {
    display:none;
}

@media screen and (max-width: 768px) {

    .nav_container {
        background-color: rgba(243, 243, 243, 0.8);
        width:100vw;
        height:50px;
        position: fixed;
        min-width:0px;
        box-shadow: 0 0 20px grey;
        backdrop-filter: blur(10px);
        z-index:1000;
        font-size:12px;
        font-weight:500;
    }
    
    .nav_content {
        /* background-color: blue; */
        /* color: rgb(195, 34, 34); */
        position: relative;
        height:50px;
        width:100%;
        margin:auto;
        /* display:flex;
        justify-content: space-between; */
    }
    
    .nav_logo_container {
        /* background-color: rgb(70, 191, 39); */
        position:absolute;
        display:block;
        height:100%;
        left:0px;
    }

    .nav_mobile_search_container {
        position:absolute;
        /* background-color:pink; */
        display:block;
        height:50px;
        width:50px;
        right:50px;
    }
    
    .nav_mobile_links_container {
        background-color: rgb(45, 176, 78);
        /* margin-left:0px; */
        position:absolute;
        /* display:block; */
        /* height:50px; */
        /* width:50px; */
        /* right:0px; */
    }
    
    .nav_account_information_container {
        /* background-color: blue; */
        display:none;
        height:100%;
        position:relative;
        /* right:25px; */
        width:250px;
    }
    
}
