.cookie_popup_container {
    display:none;
    position:fixed;
    bottom:0;
    left:50px;
    width:330px;
    /* height:100px; */
    background-color: rgb(255, 255, 255);
    padding:40px;
    border-radius: 10px 10px 0px 0px ;
    z-index:100
}

.cookie_popup_title {
    margin:0px;
    padding:0px;
}

@media (max-width: 768px) {
    .cookie_popup_container {
        width:100%;
        left:0px;
        border-radius: 15px 15px 0px 0px ;
        box-sizing: border-box;
        padding:1.5em;
    }

    .cookie_popup_buttons {
        display:grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 5px;
    }
}